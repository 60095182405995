import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import API from '../../Api/Api';
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import AdminRoute from "../../Route/RouteDetails";
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";

const ViewWorkshopDetails = () => {
    const [workshopDetails, setWorkshopDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const { id } = useParams();

    console.log('workshop', workshopDetails);


    const dateFormatter = (inputDate) => {
        console.log(inputDate);
        const date = new Date(inputDate);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
        }).format(date);
        return formattedDate;
    }

    useEffect(() => {
        try {
            API.WorkshopApis.getWorkshopDetails({
                data: id,
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Response', response);
                    setWorkshopDetails(response?.data?.data_all[0]);
                    setLoading(false)
                }
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [id])

    const handlePayment = () => {
        API.WorkshopApis.RezorPayGetId({
            data: {
                amount: workshopDetails?.amount,
                workshopId: workshopDetails._id,
            },
        })
            .then((response) => {
                console.log('Response', response);
                if (response?.data?.message === "successfully") {
                    RezorPaySdk(response?.data?.data, workshopDetails, response?.data?.key_id);
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const RezorPaySdk = (orderID, workshop, appKey) => {
        // let Amount = Math.round(amount * 1000)
        const options = {
            // key: "rzp_test_nYt1RNLg2H6FzV", // Enter the Key ID generated from the Dashboard
            key: appKey,
            amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: orderID?.currency,
            name: "RYT",
            description: orderID?.notes?.description
                ? orderID?.notes?.description
                : "Test",
            image: "https://example.com/your_logo",
            order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                console.log("success");
                handleRezorpayResponce(
                    response,
                    orderID,
                    orderID.amount,
                    "finish",
                    workshop
                );
                // handlePlanSave(workshop);
            },
            prefill: {
                name: UserData?.userdata?.name,
                email: UserData?.userdata?.email,
                contact: UserData?.userdata?.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            handleRezorpayResponce(
                response,
                orderID.id,
                orderID?.amount,
                "decline",
                workshop
            );
        });
        rzp1.open();
    };

    const handleRezorpayResponce = (
        response,
        orderID,
        amount,
        status,
        workshop
    ) => {
        API.WorkshopApis.Paymentupdate({
            data: {
                order_id: orderID.id,
                name: UserData?.userdata?.name,
                created_by: UserData?.userdata?._id,
                amount: Math.floor(amount / 100),
                responce_obj: response,
                order_status: status,
                workshopid: workshop._id,
            },
        })
            .then((response) => {
                console.log('final', response);
                if (response.data.status == 1) {
                    toast.success('Your Registration is successful');
                    navigate(`${AdminRoute.Common.Workshop}`)
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        // <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
        //     <div className="flex-grow bg-white m-0 w-full p-0" >
        //         <Header />
        //         <Sidebar />
        //         <div className="flex flex-grow md:ml-[8%] h-full md:mr-[3%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 bg-[#F5F6FB80] border border-[#E7F0FC]" >
        //             <div className="flex-grow h-full bg-transparent rounded-3xl">
        //                 <span className='text-black font-semibold text-3xl'>Upcoming Events</span>
        //                 <div className='w-full flex mt-4'>
        //                     <div className='flex-grow flex-col w-2/3 p-4'>
        //                         <div class="rounded-3xl overflow-hidden shadow-md bg-white">
        //                             <img src={workshopDetails.eventImage} alt="Event Image" />
        //                             <div className='flex flex-col p-4'>
        //                                 <div class="">
        //                                     <div class="font-bold text-xl mb-2">About Event</div>
        //                                     <p class="text-gray-700 text-base">
        //                                         {workshopDetails.eventDescription}
        //                                     </p>
        //                                 </div>
        //                                 {/* <div className='w-full flex'>
        //                                     <div className="flex justify-end pt-4 flex-col">
        //                                         <div class="text-sm mb-2 flex text-gray-700 "><FaRegCalendarAlt className='mr-2' size={18} />{formattedDate}</div>
        //                                         <div class=" text-sm mb-2 flex text-gray-700"><FaRegClock className='mr-2' size={18} />{data.eventStartTime}{" - "}{data.eventEndTime} </div>
        //                                     </div>
        //                                     <div className='flex flex-grow flex-col justify-center items-center h-full pl-4'>
        //                                         <div class="text-2xl font-bold flex items-center text-[#28BA82] mb-1">
        //                                             <FaRupeeSign className="mr-1" size={25} />
        //                                             {data.amount}
        //                                         </div>
        //                                         <button className='bg-[#FFB81D] px-4 py-2 text-black text-sm rounded-lg shadow-lg' onClick={onClick}>
        //                                             View Details
        //                                         </button>
        //                                     </div>
        //                                 </div> */}

        //                             </div>



        //                         </div>
        //                     </div>
        //                     <div className='w-1/3 flex-col flex p-4'>
        //                         <div class="rounded-3xl overflow-hidden shadow-md bg-white">
        //                             <div className='flex flex-col p-4'>
        //                                 <div class="font-bold text-xl mb-2">{workshopDetails.eventName}</div>
        //                                 <p class="text-gray-700 text-base">
        //                                     {workshopDetails.eventDescription}
        //                                 </p>
        //                                 <div className="flex justify-end py-4 flex-col">
        //                                     <div class="text-sm mb-2 flex text-gray-700 "><FaRegCalendarAlt className='mr-2' size={18} />{workshopDetails.eventDate ? dateFormatter(workshopDetails.eventDate) : null}</div>
        //                                     <div class=" text-sm mb-2 flex text-gray-700"><FaRegClock className='mr-2' size={18} />{workshopDetails.eventStartTime}{" - "}{workshopDetails.eventEndTime} </div>
        //                                 </div>
        //                                 <div className='border-t border-[#C7C6C6] pt-2'>
        //                                     <div className='flex justify-between items-center h-full pl-4'>
        //                                         <div class="text-2xl font-bold flex items-center text-[#28BA82] mb-1">
        //                                             <FaRupeeSign className="mr-1" size={25} />
        //                                             {workshopDetails.amount}
        //                                         </div>
        //                                         <button className='bg-[#FFB81D] px-4 py-2 text-black text-sm rounded-lg shadow-lg' onClick={handlePayment}>
        //                                             Book Now
        //                                         </button>
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>

        //             </div>
        //         </div>
        //     </div>
        //     <div className="hero_area relative z-0">
        //         <svg
        //             className="waves"
        //             xmlns="http://www.w3.org/2000/svg"
        //             viewBox="0 24 150 28"
        //             preserveAspectRatio="none"
        //             shapeRendering="auto"
        //         >
        //             <defs>
        //                 <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        //             </defs>
        //             <g className="parallax">
        //                 <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
        //                 <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
        //                 <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
        //                 <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
        //             </g>
        //         </svg>
        //     </div>


        // </div>
        <div className="grid grid-rows-[auto_1fr_auto] bg-white w-full h-screen overflow-hidden">
            <Sidebar />
            <div className="flex-none bg-white">
                <Header />
            </div>
            <div
                className="row-span-1 lg:ml-[8%] md:ml-[14%] md:mr-[3%] md:my-8 overflow-hidden relative z-1 rounded-2xl p-2 bg-[#F5F6FB80] border border-[#E7F0FC]"
            >
                <div className="flex-grow h-full p-4 ">
                    <span className='text-black font-semibold text-3xl'>Upcoming Events</span>
                    <div className='w-full flex flex-col lg:flex-row mt-4 scrollbar-hide max-h-[calc(100vh-280px)] overflow-y-auto'>
                        <div className='flex-grow flex-col w-full lg:w-2/3 mb-4'>
                            <div class="rounded-3xl overflow-hidden shadow-md bg-white">
                                <img src={workshopDetails.eventImage} alt="Event Image" />
                                <div className='flex flex-col p-4'>
                                    <div class="">
                                        <div class="font-bold text-xl mb-2">About Event</div>
                                        <p class="text-gray-700 text-base">
                                            {workshopDetails.eventDescription}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3 flex-col flex lg:px-4 '>
                            <div class="rounded-3xl overflow-hidden shadow-md bg-white">
                                <div className='flex flex-col p-4'>
                                    <div class="font-bold text-xl mb-2">{workshopDetails.eventName}</div>
                                    <p class="text-gray-700 text-base">
                                        {workshopDetails.eventDescription}
                                    </p>
                                    <div className="flex justify-end py-4 flex-col">
                                        <div class="text-sm mb-2 flex text-gray-700 "><FaRegCalendarAlt className='mr-2' size={18} />{workshopDetails.eventDate ? dateFormatter(workshopDetails.eventDate) : null}</div>
                                        <div class=" text-sm mb-2 flex text-gray-700"><FaRegClock className='mr-2' size={18} />{workshopDetails.eventStartTime}{" - "}{workshopDetails.eventEndTime} </div>
                                    </div>
                                    <div className='border-t border-[#C7C6C6] pt-2'>
                                        <div className='flex justify-between items-center h-full pl-4'>
                                            <div class="text-2xl font-bold flex items-center text-[#28BA82] mb-1">
                                                <FaRupeeSign className="mr-1" size={25} />
                                                {workshopDetails.amount}
                                            </div>
                                            <button className='bg-[#FFB81D] px-4 py-2 text-black text-sm rounded-lg shadow-lg' onClick={handlePayment}>
                                                Book Now
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-none hero_area relative z-0">
                <svg
                    className="waves w-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path
                            id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default ViewWorkshopDetails