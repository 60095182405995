import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import defaultUser from "../../Assets/Images/defaultUserImage.jpeg";

export default function DigitalNotesDetails({ data, image }) {
  const timestamp = data.createdAt;
  const dateObj = new Date(timestamp);
  const date = dateObj.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return (
    <Accordion allowZeroExpanded className="w-full">
      <AccordionItem className='shadow-custom-notreviewed bg-white p-1 rounded-lg'>
        <AccordionItemHeading>
          <AccordionItemButton className="w-full text-xl font-semibold text-left p-2">
            <div className="flex items-center p-1 w-full h-full">
              <div className="flex-shrink-0 h-12 w-12 md:w-16 md:h-16 rounded-full overflow-hidden">
                <img
                  src={image ? image : defaultUser}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col mx-4 flex-grow">
                <div className="flex justify-between items-center w-full">
                  <h3 className="text-lg font-bold text-gray-900 break-all whitespace-normal overflow-hidden">{data.title}</h3>
                  <span className="text-sm text-gray-600 font-semibold hidden md:block"> {time} {' , '}{date}</span>
                </div>
              </div>
              <span className="transform transition-transform duration-300 ease-in-out group-open:rotate-180">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600 group-open:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="px-4 py-2 border-t-2 border-gray-300">
          <p className="text-gray-700 break-words whitespace-normal overflow-hidden">
            {data.description}
          </p>
        </AccordionItemPanel>

      </AccordionItem>

      {data.response && (
        <AccordionItem className='shadow-custom-reviewed bg-white p-1 rounded-lg mt-4'>
          <AccordionItemHeading>
            <AccordionItemButton className="w-full text-xl font-semibold text-left p-2">
              <div className="flex items-center p-1 w-full h-full">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <img
                    src={data.response.image ? data.response.image : defaultUser}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="flex flex-col mx-4 flex-grow">
                  <div className="flex justify-between items-center w-full">
                    <h3 className="text-lg font-bold text-gray-900 truncate">{data.response.theropistname}</h3>
                  </div>
                </div>
                <span className="transform transition-transform duration-300 ease-in-out group-open:rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-600 group-open:text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="px-4 py-2 border-t-2 border-gray-300">
            <p className="text-gray-700 break-words whitespace-normal overflow-hidden">
              {data.response.responce}
            </p>
          </AccordionItemPanel>

        </AccordionItem>
      )}
    </Accordion>
  );
}
