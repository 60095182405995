import React from 'react'

const WellCards = ({ heading, imgSrc, onClick }) => {
    return (
        <div className='flex flex-col px-12 justify-center items-center max-w-sm rounded-2xl overflow-hidden shadow-md mb-4 bg-white' onClick={onClick}>
            <span className='text-black text-lg font-semibold my-2'>{heading}</span>
            <div className='h-24 w-auto'>
                <img src={imgSrc} className='w-full h-full object-cover'/>
            </div>
        </div>
    )
}

export default WellCards