import React from 'react'
import { FaStar } from "react-icons/fa";
import defaultUser from "../../Assets/Images/defaultUserImage.jpeg";


const DigitalNotesList = ({ data, image, onClick }) => {
    console.log(data);
    const timestamp = data.createdAt;
    const dateObj = new Date(timestamp);
    const date = dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
    const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
        // <div className={`flex flex-row items-center justify-between w-full max-w-2xl rounded-lg overflow-hidden bg-white ${data.response ? 'shadow-custom-reviewed ' : 'shadow-custom-notreviewed '} border-2 mb-4`} onClick={onClick}>
        //     <div className="flex items-center p-2 md:p-8 w-full h-full">
        //         <div className="w-12 h-12 md:w-16 md:h-16 rounded-full overflow-hidden">
        //             <img
        //                 src={image ? image : defaultUser}
        //                 alt="Profile"
        //                 className="w-full h-full object-cover"
        //             />
        //         </div>
        //         <div className="flex flex-col ml-4 flex-grow">
        //             <div className="flex justify-between items-center w-full">
        //                 <h3 className="text-lg font-bold text-gray-900 truncate mb-0">{data.title}</h3>
        //                 <span className="text-sm text-gray-600 font-semibold">{date}</span>
        //             </div>
        //             <div className="flex justify-between items-center w-full pr-4">
        //                 <div className='flex flex-col'>
        //                     <p className="text-gray-700 text-sm truncate mb-0">{data.description.length > 40 ? data.description.slice(0, 40) + '...' : data.description}</p>
        //                     <span className="text-sm text-gray-600 font-semibold mt-1 md:mt-0">{time}</span>
        //                 </div>
        //                 {data.response && <FaStar size={35} color='#7EBA28' />}
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div
        className={`flex flex-col md:flex-row items-center justify-between w-full max-w-2xl rounded-lg overflow-hidden bg-white 
        ${data.response ? 'shadow-custom-reviewed' : 'shadow-custom-notreviewed'} 
        border-2 mb-4`}
        onClick={onClick}
    >
        <div className="flex items-start md:items-center p-4 w-full h-full">
            {/* Profile Image */}
            <div className="flex-shrink-0 w-12 h-12 md:w-16 md:h-16 rounded-full overflow-hidden">
                <img
                    src={image ? image : defaultUser}
                    alt="Profile"
                    className="w-full h-full object-cover"
                />
            </div>
    
            {/* Content Section */}
            <div className="flex flex-col ml-4 flex-grow">
                {/* Title and Date */}
                <div className="flex justify-between w-full mb-2">
                    <h3 
                        className="text-base md:text-lg font-bold text-gray-900 break-all whitespace-normal overflow-hidden mb-0"
                    >
                        {data.title}
                    </h3>
                    <span className="text-xs md:text-sm text-gray-600 font-semibold hidden md:block">
                        {date}
                    </span>
                </div>
    
                {/* Description and Time */}
                <div className="flex justify-between w-full">
                    <p 
                        className="text-gray-700 text-sm break-all whitespace-normal overflow-hidden mb-0"
                    >
                        {data.description.length > 40 ? `${data.description.slice(0, 40)}...` : data.description}
                    </p>
                    <span className="text-xs md:text-sm text-gray-600 font-semibold hidden md:block">
                        {time}
                    </span>
                </div>
            </div>
    
            {/* Star Icon */}
            {data.response && (
                <FaStar
                    size={24}
                    color="#7EBA28"
                    className="hidden md:block ml-4"
                />
            )}
        </div>
    
        {/* Mobile Star Icon */}
        {data.response && (
            <div className="flex justify-center items-center w-full md:hidden p-2">
                <FaStar size={24} color="#7EBA28" />
            </div>
        )}
    </div>
    
    )
}

export default DigitalNotesList;