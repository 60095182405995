import React, { useState, useEffect, useContext } from "react";
import API from "../../Api/Api";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import groupBg from "../../Assets/Images/Groups/GroupsBG.png";
import GroupDetailsPage from "./GroupDetailsPage";
import GroupCards from "./GroupCards";
import { CiSearch } from "react-icons/ci";
import preloader from '../../Assets/Images/Flip-Flop.gif';
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { PlanContext } from "../../ContextApi/PlanContext";
import { CiLock } from "react-icons/ci";

const Groups = () => {

  const navigate = useNavigate();

  const tabs = [
    { name: 'Focus', value: 'focused' },
    { name: 'Support', value: 'support' },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]?.value);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [GroupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const { planDetails } = useContext(PlanContext);

  const GroupListfun = () => {
    setLoading(true);

    try {
      API.Auth.Support_API({
        support: "support",
        // serchKey: '',
        ingroup: "yes",
      }).then((response) => {
        if (response.data.status === 1) {
          console.log('support', response);
          setGroupList(response?.data?.data_all);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GroupListfun();
  }, []);

  const filtered = GroupList.filter((group) =>
    group.name.toLowerCase().includes(searchText.toLowerCase()) ||
    group.description.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };


  return (
    <>
      {/* <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
        <div className="flex-grow bg-white m-0 w-full p-0" style={{
          background: `url(${groupBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
        }}>
          <Header />
          <Sidebar />
          <div className="flex flex-grow md:ml-[8%] h-full md:mr-[4%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 radialGradient " >
            <div className="w-full md:w-1/3 h-full bg-white mr-4 rounded-3xl p-2 pt-4">
              <div className="flex flex-col justify-center items-center w-full">
                <div className="flex justify-between items-center w-full px-4">
                  <span className="text-[#303030] font-bold text-3xl">Groups</span>
                  {planDetails != null && planDetails.includedPages.includes("TherapySession") ? (
                    <button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs" onClick={() => navigate(`../${AdminRoute?.Common?.Join_Group}`)}>
                      Join Group
                    </button>
                  ) : (<button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs">
                    <div className='flex justify-center items-center'>
                      <CiLock className='mr-2' size={20} /> Join Group
                    </div>
                  </button>)}
                </div>
                <div className="w-full text-sm font-medium text-center p-3">
                  <div className='flex flex-col justify-start items-start'>
                    <div className='flex justify-center items-center pb-2 w-full'>
                      <div class="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <CiSearch size={18} />
                        </div>
                        <input type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full  block w-full ps-10 p-2.5  outline-none" placeholder="Search Group" onChange={(e) => setSearchText(e.target.value)} />
                      </div>
                    </div>
                    <div className="w-full flex flex-col mt-2 min-h-[53vh]">
                      {loading ? (
                        <div className="w-full flex justify-center items-center">
                          <div className="h-10 w-10 rounded-full">
                            <img src={preloader} className="h-full w-full object-cover" alt="Loading..." />
                          </div>
                        </div>
                      ) : filtered.length > 0 ? (
                        <div className="w-full grid grid-cols-1 max-h-[53vh] overflow-y-auto scrollbar-hide">
                          {filtered.map((data, index) => (
                            <GroupCards
                              key={index}
                              data={data}
                              onClick={() => handleGroupClick(data)}
                              isSelected={selectedGroup?._id === data._id}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="flex justify-center flex-col items-center">
                          <p className="text-sm text-black font-semibold">No groups Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-2/3 h-full bg-white rounded-3xl hidden md:block">
              {selectedGroup ? (
                <GroupDetailsPage group={selectedGroup} />
              ) : (
                <div className="flex justify-center items-center h-full min-h-[75vh] ">
                  <p className="text-sm text-gray-500 font-semibold">Select a group to view details</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hero_area relative z-0">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>


      </div> */}

      <div className="grid grid-rows-[auto_1fr_auto] bg-white mx-0 w-full h-screen" style={{
        background: `url(${groupBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
      }}>
        <Sidebar />
        <div className="flex-none bg-white">
          <Header />
        </div>
        <div className="row-span-1 flex lg:ml-[8%] md:ml-[14%] md:mr-[4%] md:my-8 overflow-hidden z-1 radialGradient md:p-4">
          <div className="w-full hidden lg:flex">
            <div className="w-1/3 bg-white mr-4 rounded-3xl p-2 pt-4">
              <div className="flex flex-col justify-center items-center w-full">
                <div className="flex justify-between items-center w-full px-4">
                  <span className="text-[#303030] font-bold text-3xl">Groups</span>
                  {planDetails != null && planDetails.includedPages.includes("TherapySession") ? (
                    <button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs" onClick={() => navigate(`../${AdminRoute?.Common?.Join_Group}`)}>
                      Join Group
                    </button>
                  ) : (<button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs">
                    <div className='flex justify-center items-center'>
                      <CiLock className='mr-2' size={20} /> Join Group
                    </div>
                  </button>)}
                </div>
                <div className="w-full text-sm font-medium text-center p-3">
                  <div className='flex flex-col justify-start items-start'>
                    <div className='flex justify-center items-center pb-2 w-full'>
                      <div class="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <CiSearch size={18} />
                        </div>
                        <input type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full  block w-full ps-10 p-2.5  outline-none" placeholder="Search Group" onChange={(e) => setSearchText(e.target.value)} />
                      </div>
                    </div>
                    <div className="w-full flex flex-col mt-2 min-h-[53vh]">
                      {loading ? (
                        <div className="w-full flex justify-center items-center">
                          <div className="h-10 w-10 rounded-full">
                            <img src={preloader} className="h-full w-full object-cover" alt="Loading..." />
                          </div>
                        </div>
                      ) : filtered.length > 0 ? (
                        <div className="w-full grid grid-cols-1 max-h-[53vh] overflow-y-auto scrollbar-hide">
                          {filtered.map((data, index) => (
                            <GroupCards
                              key={index}
                              data={data}
                              onClick={() => handleGroupClick(data)}
                              isSelected={selectedGroup?._id === data._id}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="flex justify-center flex-col items-center">
                          <p className="text-sm text-black font-semibold">No groups Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/3 h-full bg-white rounded-3xl">
              {selectedGroup ? (
                <GroupDetailsPage group={selectedGroup} />
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-sm text-gray-500 font-semibold">Select a group to view details</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex lg:hidden p-4">
            <div className="bg-white rounded-3xl p-2 w-full">
              <div className="flex flex-col justify-center items-center w-full">
                <div className="flex justify-between items-center w-full px-4">
                  <span className="text-[#303030] font-bold text-xl">Groups</span>
                  {planDetails != null && planDetails.includedPages.includes("TherapySession") ? (
                    <button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs" onClick={() => navigate(`../${AdminRoute?.Common?.Join_Group}`)}>
                      Join Group
                    </button>
                  ) : (<button className="mt-2 h-8 bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs">
                    <div className='flex justify-center items-center'>
                      <CiLock className='mr-2' size={20} /> Join Group
                    </div>
                  </button>)}
                </div>
                <div className="w-full text-sm font-medium text-center p-2">
                  <div className='flex flex-col justify-start items-start'>
                    <div className='flex justify-center items-center pb-2 mt-4 w-full'>
                      <div class="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <CiSearch size={18} />
                        </div>
                        <input type="text" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-full  block w-full ps-10 p-2.5  outline-none" placeholder="Search Group" onChange={(e) => setSearchText(e.target.value)} />
                      </div>
                    </div>
                    <div className="w-full flex flex-col mt-2 ">
                      {loading ? (
                        <div className="w-full flex justify-center items-center">
                          <div className="h-10 w-10 rounded-full">
                            <img src={preloader} className="h-full w-full object-cover" alt="Loading..." />
                          </div>
                        </div>
                      ) : filtered.length > 0 ? (
                        <div className="w-full grid grid-cols-1 overflow-y-auto scrollbar-hide max-h-[calc(100vh-300px)]">
                          {filtered.map((data, index) => (
                            <GroupCards
                              key={index}
                              data={data}
                              onClick={() =>
                                navigate(`${AdminRoute.Common.groupDetail}/${data._id}`, {
                                  state: { groupType: data.type },
                                })
                              }
                              isSelected={selectedGroup?._id === data._id}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="flex justify-center flex-col items-center">
                          <p className="text-sm text-black font-semibold">No groups Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-none hero_area relative z-0">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export default Groups;
