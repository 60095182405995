import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminRoute from "../Route/RouteDetails";
import API from '../Api/Api';
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";

const SessionPaymentModal = ({ isOpen, onClose,paymentSuccess }) => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabNames = ['Monthly', 'Quarterly', 'Half Yearly'];

    const handlePayment = () => {
        API.PayPerFeatures.RezorPayGetId({
            data: {
                amount: '999',
            },
        })
            .then((response) => {
                console.log('Response', response);
                if (response?.data?.message === "successfully") {
                    RezorPaySdk(response?.data?.data, response?.data?.key_id);
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const RezorPaySdk = (orderID, appKey) => {
        // let Amount = Math.round(amount * 1000)
        const options = {
            // key: "rzp_test_nYt1RNLg2H6FzV", // Enter the Key ID generated from the Dashboard
            key: appKey,
            amount: orderID?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: orderID?.currency,
            name: "RYT",
            description: orderID?.notes?.description
                ? orderID?.notes?.description
                : "Test",
            image: "https://example.com/your_logo",
            order_id: orderID.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                console.log("success");
                handleRezorpayResponce(
                    response,
                    orderID,
                    orderID.amount,
                    "finish",
                );
                // handlePlanSave(workshop);
            },
            prefill: {
                name: UserData?.userdata?.name,
                email: UserData?.userdata?.email,
                contact: UserData?.userdata?.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            handleRezorpayResponce(
                response,
                orderID.id,
                orderID?.amount,
                "decline",
            );
        });
        rzp1.open();
    };

    const handleRezorpayResponce = (
        response,
        orderID,
        amount,
        status,
    ) => {
        API.PayPerFeatures.Paymentupdate({
            data: {
                order_id: orderID.id,
                name: UserData?.userdata?.name,
                created_by: UserData?.userdata?._id,
                amount: Math.floor(amount / 100),
                responce_obj: response,
                order_status: status,
            },
        })
            .then((response) => {
                if (response.data.status == 1) {
                    // toast.success('Your Registration is successful');
                    // // navigate(`${AdminRoute.Common.Workshop}`)
                    paymentSuccess();
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-3xl max-w-[90%] relative overflow-hidden flex flex-col p-4  bg-gradient-to-b from-white to-[#faf5da] shadow-md border border-[#d8d8d8]" >
                <button
                    className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                <div className='flex justify-center items-center mt-2'>
                    <span className='text-xl text-black font-bold'> Get more Session Here </span>
                </div>
                <div className='flex justify-center items-center my-4'>
                    <span className='text-md text-black font-bold'> You donot have any session </span>
                </div>
                {/* <div className='flex flex-col justify-start items-start mt-2'>
                    <span className='text-sm text-gray-500 font-bold'> Select your plan </span>
                </div>
                <div className='flex flex-col justify-start items-start mt-1'>
                    <nav
                        className="relative z-0 flex border rounded-xl overflow-hidden dark:border-neutral-700 outline-none"
                        aria-label="Tabs"
                        role="tablist"
                        aria-orientation="horizontal"
                    >
                        {tabNames.map((tab, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`${activeTab === index ? `text-black  bg-[#feb004]` : `text-gray-500 `}
                                 relative flex-1 border-s py-3 px-4  text-sm font-medium text-center overflow-hidden outline-none leading-normal whitespace-nowrap`}
                                id={`tab-item-${index}`}
                                aria-selected={activeTab === index}
                                onClick={() => handleTabClick(index)}
                                role="tab"
                            >
                                {tab}
                            </button>
                        ))}
                    </nav>
                    <p className="text-sm pb-1 text-gray-600 font-semibold w-full text-left mt-2">
                        {activeTab === 0
                            ? "1 Session"
                            : activeTab === 1
                                ? "4 Session"
                                : activeTab === 2
                                    ? "8 Session"
                                    : null}

                    </p>
                </div> */}

                <div className='flex justify-between items-center md:px-2'>
                    <button
                        className="bg-gradient-to-r from-[#ffdf07] to-[#8bed93] text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs md:text-sm shadow "
                        onClick={() => navigate(`${AdminRoute.Common.Plan}`)}
                    >
                        UPGRADE
                    </button>
                    <button
                        className="bg-white shadow text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs " style={{ border: '1px solid #ffdf07' }}
                        onClick={handlePayment}
                    >
                        Book a session
                    </button>


                </div>
            </div>
        </div>
    )
}

export default SessionPaymentModal