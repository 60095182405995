import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";

const MoodTracker = () => {
  return (
    <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
      <div className="flex-grow bg-white m-0 w-full p-0" >
        <Header />
        <Sidebar />
        <div className="flex flex-grow md:ml-[8%] h-full md:mr-[3%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 bg-[#F5F6FB80] border border-[#E7F0FC]" >
          <div className="flex w-full bg-transparent rounded-3xl">
            <span>MOOD GRAPH</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoodTracker;