import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import API from "../../Api/Api";
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import AdminRoute from "../../Route/RouteDetails";
import SessionsIcon from "../../Assets/Images/sidebar/MeetingRoom.png"
import Groupsicon from "../../Assets/Images/sidebar/People.png";
import AiIcon from "../../Assets/Images/sidebar/MessageBot.png";
import logoutArrow from "../../Assets/Images/sidebar/Logout.png";
import { FaHome } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { TbPuzzleFilled } from "react-icons/tb";
import defaultUser from "../../Assets/Images/defaultUser.png";
import { TbCalendarEvent } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  // const featureListContext = useContext(FeatrureList);
  const [isExpanded, setIsExpanded] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));

  const Logoutfun = () => {
    localStorage.removeItem("EmailId");
    localStorage.removeItem("AuthTokenClient");
    localStorage.removeItem("ClientUserData");
    navigate(`../${AdminRoute?.Auth?.Login}`)
  }

  const isActive = (path) => {
    if (path === '/groups' && location.pathname.startsWith('/ChatBox')) {
      return true; // Consider /ChatBox as active for groups
    }
    return location.pathname === path;
  };


  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };



  // function getFirstName(fullName) {
  //   return fullName.split(' ')[0];
  // }

  return (
    <>
      <div className="absolute z-15 md:hidden">
        <div
          className={`fixed top-1/2 left-0 transform -translate-y-1/2 bg-gray-300 p-2 rounded-r-lg cursor-pointer z-20 ${isExpanded ? "translate-x-[15rem]" : "translate-x-0"
            } transition-all duration-300`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <IoIosArrowBack className="text-xl text-gray-700" />
          ) : (
            <IoIosArrowForward className="text-xl text-gray-700" />
          )}
        </div>
      </div>
      <div
        className={`${isExpanded ? 'block':'hidden'} md:block fixed top-28 left-0 bottom-6 pt-2 px-2 rounded-r-[2rem] cursor-pointer bg-white ${isExpanded ? "w-64" : "w-24"}  overflow-hidden z-10 shadow-lg`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`flex items-center p-2 ${isExpanded ? 'justify-start' : 'justify-center'}`} onClick={() => navigate(`../${AdminRoute.Common.Profile}`)}>
          <img src={UserData?.userdata?.image ? UserData?.userdata?.image : defaultUser} alt="User" className="w-16 h-16 rounded-full" />
          {isExpanded && <span className="ml-4 text-sm font-semibold">{UserData?.userdata?.name}</span>}
        </div>
        <div
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => navigate('/')}
        >
          <FaHome className={`w-6 h-6  `} color="#000" />
          {isExpanded && <span className="ml-4 text-sm">Home</span>}
        </div>
        <div
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/DigitalNotes') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => navigate('/DigitalNotes')}
        >
          <GiNotebook className={`w-6 h-6  `} color="#000" />
          {isExpanded && <span className="ml-4 text-sm">Digital Notes</span>}
        </div>
        {[
          { path: '/MySession', icon: SessionsIcon, label: 'Sessions' },
          { path: '/Groupchat', icon: Groupsicon, label: 'Groups' },
          { path: '/Aitherapist', icon: AiIcon, label: 'Ai Assistance' },
        ].map(({ path, icon, label }) => (
          <div
            key={path}
            className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive(path) ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
            onClick={() => navigate(path)}
          >
            <img src={icon} alt={label} className={`w-6 h-6  `} />
            {isExpanded && <span className="ml-4 text-sm">{label}</span>}
          </div>
        ))}
        <div
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/Playground') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => navigate('/Playground')}
        >
          <TbPuzzleFilled className={`w-6 h-6  `} color="#000" />
          {isExpanded && <span className="ml-4 text-sm">Playground</span>}
        </div>
        <div
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer ${isActive('/upcomingEvents') ? (isExpanded ? 'bg-[#F8D68B]' : 'bg-[#FAF5DA]') : ''} rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => navigate('/upcomingEvents')}
        >
          <TbCalendarEvent className={`w-6 h-6  `} color="#000" />
          {isExpanded && <span className="ml-4 text-sm">Upcoming Events</span>}
        </div>
        <div
          className={`flex items-center my-2 mx-2 p-2 cursor-pointer rounded-md  ${isExpanded ? 'justify-start' : 'justify-center'}`}
          onClick={() => Logoutfun()}
        >
          <img src={logoutArrow} alt="Logout" className="w-6 h-6  " />
          {isExpanded && <span className="ml-4 text-sm">Logout</span>}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
