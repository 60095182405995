import React, { useState, useContext,useEffect } from 'react';
import logo from "../../Assets/Images/Login/ryteLife-full.png";
import dashboardModalImage from "../../Assets/Images/dashboard/dashboardModalImage.png";
import { PlanContext } from "../../ContextApi/PlanContext";
import API from "../../Api/Api";
import { useNavigate } from 'react-router-dom';
import AdminRoute from "../../Route/RouteDetails";

const DashboardModal = ({isOpen, onClose}) => {
    const [PlanList, setPlanList] = useState([]);
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const { planDetails, setPlanDetails } = useContext(PlanContext);
    const navigate = useNavigate();


    useEffect(() => {
        getPlanList();
        MyPlanlfeatures();
    }, []);

    const MyPlanlfeatures = () => {
        try {
            API.Payment.MyPlanlfeatures({
                data: UserData.userdata.role
            }).then((response) => {
                if (response.status === 200) {
                    console.log('planFeatures', response)
                    setPlanDetails(response.data.plandetails);
                }
            });
        } catch (error) {
            false(false);
            console.log(error);
        }
    };
    //api call for plan list
    const getPlanList = () => {
        
        try {
            API.Auth.PlanPlanListAPI({}).then((response) => {
                if (response?.data?.status === 1) {
                    setPlanList(response?.data?.data);
                }
               
            });
        } catch (error) {
           
            console.log(error);
        }
    };

    const handlePlanSave = (plan_id) => {
        console.log('plan_id', plan_id);
        API.Payment.Adduserplan({
            data: {
                planid: plan_id,
                UserId: UserData?.userdata?._id,
            },
        })
            .then((response) => {
                console.log(response?.data);
                if (response?.data?.status === 1) {
                    MyPlanlfeatures();
                    onClose();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-3xl max-w-[90%] relative overflow-hidden flex flex-col p-3  bg-gradient-to-b from-white to-[#faf5da] shadow-md border border-[#d8d8d8]" >
                <div className='flex justify-center items-center'>
                    <img src={logo} alt='Rytlife' className='h-24 w-auto object-contain' />
                </div>
                <div className='flex justify-center items-center'>
                    <img src={dashboardModalImage} alt='Rytlife' className='h-96 w-auto object-contain' />
                </div>
                <div className='flex justify-between items-center md:px-2'>
                    <button
                        className="bg-gradient-to-r from-[#ffdf07] to-[#8bed93] text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs md:text-sm shadow "
                    onClick={() => navigate(`${AdminRoute.Common.Plan}`)}
                    >
                        EXPLORE NOW
                    </button>
                    {PlanList?.length &&
                        PlanList.map((plan, i) => {
                            if (plan.name === "Trial") {
                                return (

                                    <button
                                        className="bg-white shadow text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs " style={{ border: '1px solid #ffdf07' }}
                                        onClick={() => handlePlanSave(plan._id)}
                                    >
                                        CONTINUE WITH FREE TRAIL
                                    </button>

                                );
                            }
                            return null;
                        })}

                </div>
            </div>
        </div>
    )
}

export default DashboardModal