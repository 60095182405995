/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, useRef } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import API from "../../Api/Api";
import Sidebar from "../Common/Sidebar";
import AiBg from "../../Assets/Images/gradient1.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoSend } from "react-icons/io5";

const AitherapistCore = () => {
	const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const megID = queryParams.get("id");
	const [isRecording, setIsRecording] = useState(false);
	const textareaRef = useRef(null);
	const [rows, setRows] = useState(1);
	const navigate = useNavigate();
	const [UserInfo, setUserInfo] = useState([]);
	const [AiInputChat, setAiInputChat] = useState("");
	const [AIConversation, setAIConversation] = useState([]);
	const [isGenerating, setisGenerating] = useState("");
	const [chatHistory, setChatHistory] = useState([]);
	const [AIChat, setAIChat] = useState([]);
	const [currentChatId, setcurrentChatId] = useState("");
	const [perviousChatList, setPerviousChatList] = useState([])
	const [isOpen, setIsOpen] = useState(false);

	const chatHistoryRef = useRef(null);

	useEffect(() => {
		if (chatHistoryRef.current) {
			chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
		}
	}, [AIConversation]);

	const convertBlobToBinaryData = (blob) => {

		const reader = new FileReader();
		reader.onload = () => {
			const binaryData = reader.result;
			sendBinaryDataToBackend(binaryData, blob);
		};
		reader.readAsArrayBuffer(blob);
	};
	const sendBinaryDataToBackend = async (binaryData, blob, phone) => {
		const data = new FormData();

		data.append("file", blob, "output.mp3");
		data.append("phone", UserData?.userdata?.phone)
		const encrypted = "51565400e6d1cb014ed02db50fa535b7"
		// /api/aiconnect/creatchattranscribe
		axios
			.post(`https://aitapi.rytlife.com:8001/transcribe_cms_connect`, data, {
				headers: {
					// Authorization: UserData.token,

					'Content-Type': 'multipart/form-data',
					'x-api-key': encrypted
				},
			})
			.then((response) => {
				if (response?.data?.transcription) {
					handleSubmitChat(response?.data?.transcription)
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error(error?.data?.message);
			});
	};


	const recorderControls = useAudioRecorder(
		{
			noiseSuppression: true,
			echoCancellation: true,
		},
		(err) => console.table(err) // onNotAllowedOrFound
	);
	const addAudioElement = (blob) => {
		convertBlobToBinaryData(blob);
	};


	const onRecord = (recState) => {
		setIsRecording(!recState);
	};


	const toggleDropdown = () => {
		setIsOpen((prevState) => !prevState);  // Toggle the state
	};

	useEffect(() => {
		getChatMsgID();
		chatlisthistory();
		if (megID) {
			getChatHistory(megID);
			setcurrentChatId(megID);
		}
		getChatHistory();
	}, [])


	useEffect(() => {
		const textarea = textareaRef.current;
		if (rows < 5) {
			setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
		}
		if (AiInputChat?.length === 0) {
			setRows(1);
		}

		// if (textarea.clientHeight < textarea.scrollHeight) {
		//   setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
		// }else if(textarea.clientHeight > textarea.scrollHeight){
		//   setRows(Math.max(1, Math.floor(textarea.srollHeight / 30)));
		// }
	}, [AiInputChat]);

	const getChatMsgID = () => {
		API.Therapist.CreatMessageid({
			data: {
				phone: UserData?.userdata?.phone,
				name: UserData?.userdata?.name
			}
		}).then((response) => {
			let data = JSON.parse(response?.data?.data)
			setAIChat(JSON.parse(response?.data?.data));
			setcurrentChatId(data?.message_id);
		}).catch((err) => {
			console.log(err)
		})
	}

	const chatlisthistory = () => {
		API.MyThreapist.Aichatlisthistory()
			.then((response) => {
				if (response?.data?.status === 1) {
					setPerviousChatList(response?.data?.userchat)
				}
			}).catch((err) => {
				console.log(err)
			})
	}


	const getChatHistory = (megID) => {
		API.Therapist.ChatHistory({
			msgId: megID,
			name: UserData?.userdata?.name,
			phone: UserData?.userdata?.phone,
		})
			.then((response) => {
				// let data = JSON.parse(response.data.data)
				if (response?.data?.status === 1) {
					// setChatHistory(response?.data?.userchat)
					if (megID) {
						setAIConversation(response?.data?.userchat)
					} else {
						setChatHistory(response?.data?.userchat)
					}
				}
				;
			}).catch((err) => {
				console.log(err)
			})
	}




	const handleChange = (e) => {
		setAiInputChat(e.target.value);
	};

	// =====================api call for chat section =================================

	const handleSubmitChat = (Msg) => {

		// if (AiInputChat.length) {
		setisGenerating(true);
		AIConversation?.length == 0
			? setAIConversation((prevArray) => [
				...prevArray,
				{
					content: AIChat?.output?.content,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "assistant",
				},
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			])
			: setAIConversation((prevArray) => [
				...prevArray,
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			]);
		API.Therapist.Chatapi({
			data: {
				message_id: megID ? megID : currentChatId,
				phone: UserData?.userdata?.phone,
				name: UserData?.userdata?.name,
				message: Msg
			}
		}).then((response) => {
			if (response.data.status === 1) {
				let data = JSON.parse(response?.data?.data)
				setAiInputChat("");
				setAIConversation((prevArray) => [
					...prevArray,
					{
						content: data.data.message,
						created_at: new Date(),
						role: "assistant",
					},
				]);
				navigate(
					`../${AdminRoute?.Common?.Aitherapist}?id=${data?.data?.message_id}`,
					{ replace: true }
				)
			}
			setisGenerating(false);
		}).catch((err) => {
			console.log(err)
		})
		// }

	};



	const handleKeyPress = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			setRows(1);
			handleSubmitChat(AiInputChat);
			setAiInputChat("");
			e.preventDefault();
		} else if (e.key === "Enter" && e.shiftKey) {
			if (rows < 6) {
				setRows(rows + 1);
			}
			const cursorPosition = e.target.selectionStart;
			const newInputValue =
				AiInputChat.substring(0, cursorPosition) +
				"\n" +
				AiInputChat.substring(cursorPosition);
			setAiInputChat(newInputValue);
			e.preventDefault();
		}
	};

	return (
		<>
			{/* <div className="d-flex h-dashboard">
			<div className="toggleIcon aitherapist">
				<div className="container-fluid">
					<div className="row">
						<div className="nav-wrap">
							<nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
								<h2 className="heading  mt-1 mb-1">Al Therapist</h2>
								<Header UserInfo={UserInfo} />
							</nav>
						</div>
						<div className="col-12">
							<div className="row">
								<div className="col-12">
									<div
										className="ai-div mx-0 mt-0 p-2"
									>
										<div className="row">
											<div className="col-12">
												<div className="therapist-switches-div d-flex rounded-pill justify-content-end">
													<Dropdown>
														<Dropdown.Toggle variant="success" id="drNewopdown-basic">
															<i className="fa fa-ellipsis-v"></i>
														</Dropdown.Toggle>
														<Dropdown.Menu className="previouschat-dropdown chat-drop-menu">
															<Dropdown.Item onClick={() => [navigate(`../${AdminRoute?.Common?.Aitherapist}`, { replace: true }), window.location.reload(true)]}>
																<h6 className="mt-0 font-weight-medium dropdown-header">
																	New Chats{" "}
																</h6>
															</Dropdown.Item>
															<Dropdown.Item>
																<h6 className="mt-0 font-weight-medium dropdown-header">
																	Previous Chats{" "}
																</h6>
															</Dropdown.Item>
															{perviousChatList?.map((item) => (
																<Dropdown.Item
																	key={item?.message_id}
																	onClick={() => [navigate(`../${AdminRoute?.Common?.Aitherapist}?id=${item?._id}`, { replace: true }), getChatHistory(item?._id)]}>
																	<div className="preview-item-content py-1 ">
																		<p className="font-weight-medium small-text my-0">
																			{item?.name}
																		</p>
																	</div>
																</Dropdown.Item >
															))}
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
										</div>

										<div className="col-md-12 mb-2 mt-5">
											<div className="scroller px-3">
												<div className="therapist-content-center position-relative mt-5 mb-3 mb-md-0">
													{AIConversation?.length ? (
														AIConversation?.map((val, index) => {
															return (
																<div className="mb-3 w-100 chats" key={index}>
																	{val?.role === "assistant" ? (
																		<div className="d-flex justify-content-start">
																			<div className="chatbox-rec chat-reciving-light">
																				<p className="my-0 reciving-text">
																					{val?.content}
																				</p>
																			</div>
																		</div>
																	) : (
																		<div className="d-flex justify-content-end">
																			<div className="chatbox-send chat-trasmitting-light">
																				<p className="my-0 trasmitting-text">
																					{val?.content}
																				</p>
																			</div>
																		</div>
																	)}
																</div>
															);
														})
													) : AIChat?.output?.content && (
														<div className="mb-3 w-100 chats" >
															<div className="d-flex justify-content-start">
																<div className="chatbox-rec chat-reciving-light">
																	<p className="my-0 reciving-text">
																		{AIChat?.output?.content}
																	</p>
																</div>
															</div>
														</div>
													)}
													{isGenerating ? <p className="generate_loader"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></p> : <></>}
												</div>
											</div>
											<div className="send-box">
												<textarea
													ref={textareaRef}
													placeholder="Write a message..."
													className="therapist-send-box-input rounded-pill"
													rows={rows}
													cols={50}
													autoComplete="off"
													value={isGenerating ? "" : AiInputChat}
													onChange={(e) => handleChange(e)}
													onKeyDown={handleKeyPress}
													disabled={isGenerating}
													style={{ resize: "none" }}
												/>
												<div className="send-box-icon">
													<Button
														className="btn btn--send"
														title="Send message"
														tabIndex="0"
														onClick={(e) => handleSubmitChat(AiInputChat)}
													>
														<svg
															className="btn__icon"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														>
															<line x1="22" y1="2" x2="11" y2="13"></line>
															<polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
														</svg>
													</Button>
												</div>
												<div>
													<AudioRecorder
														onRecordingComplete={(blob) => addAudioElement(blob)}
														onClick={() => onRecord(isRecording)}
														recorderControls={recorderControls}
														downloadOnSavePress={false}
														downloadFileExtension="mp3"
														showVisualizer={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</div > */}
			{/* <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen">
				<div className="flex-grow bg-white m-0 w-full p-0">
					<Header />
					<div className="flex flex-grow flex-col md:ml-[8%] h-full md:mr-[4%] md:my-4 relative z-1 p-4 rounded-3xl shadow-md" style={{
						background: `url(${AiBg})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}>
						
					</div>
				</div>

				<div className="hero_area relative z-0">
					<svg
						className="waves"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 24 150 28"
						preserveAspectRatio="none"
						shapeRendering="auto"
					>
						<defs>
							<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
						</defs>
						<g className="parallax">
							<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
							<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
							<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
							<use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
						</g>
					</svg>
				</div>
			</div> */}
			<div className="grid grid-rows-[auto_1fr_auto] bg-white w-full h-screen overflow-hidden">
				<div className="flex-none bg-white">
					<Header />
				</div>
				<div
					className="row-span-1 lg:ml-[8%] md:ml-[14%] md:mr-[4%] md:my-8 overflow-hidden relative z-1 rounded-2xl p-2 mb-4"
					style={{
						background: `url(${AiBg})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<div className="flex justify-end rounded-full my-2 z-10">
						<div className="relative">
							<button
								className="border-0 p-2 text-black"
								onClick={toggleDropdown}
							>
								<BsThreeDotsVertical size={20} />
							</button>
							{isOpen && (
								<div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg overflow-hidden">
									<div
										className="cursor-pointer px-4 py-2 text-xs md:text-sm font-medium hover:bg-[#F8D27F]"
										onClick={() => [
											navigate(`../${AdminRoute?.Common?.Aitherapist}`, { replace: true }),
											window.location.reload(true),
										]}
									>
										<h6 className="my-2 text-xs md:text-sm">New Chats</h6>
									</div>
									<div className="cursor-pointer px-4 py-2 text-xs md:text-sm font-medium hover:bg-[#F8D27F] rounded-lg">
										<h6 className="my-2 text-xs md:text-sm">Previous Chats</h6>
									</div>
									{perviousChatList?.map((item) => (
										<div
											key={item?.message_id}
											className="cursor-pointer px-4 py-2 text-xs md:text-sm font-medium hover:bg-[#F8D27F] rounded-lg"
											onClick={() => [
												navigate(`../${AdminRoute?.Common.Aitherapist}?id=${item?._id}`, {
													replace: true,
												}),
												getChatHistory(item?._id),
												toggleDropdown()
											]}
										>
											<div className="py-1">
												<p className="font-medium text-xs md:text-sm my-0">{item?.name}</p>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>

					<div
						className="flex flex-col w-full overflow-y-auto mb-12 max-h-[calc(100vh-220px)] scrollbar-hide"
						ref={chatHistoryRef}
					>
						{AIConversation?.length ? (
							AIConversation.map((val, index) => (
								<div className="mb-2 flex" key={index}>
									<div
										className={`w-full flex ${val.role === "assistant" ? "justify-start" : "justify-end"
											}`}
									>
										<div
											className={`bg-white text-gray-800 mb-4 px-4 py-3 rounded-full ${val.role === "assistant"
													? "rounded-bl-none"
													: "rounded-br-none"
												} text-[10px] lg:text-sm leading-relaxed break-words whitespace-normal w-fit`}
										>
											{val.content}
										</div>
									</div>
								</div>
							))
						) : (
							AIChat?.output?.content && (
								<div className="mb-4">
									<div className="bg-white text-gray-800 px-4 py-3 rounded-full rounded-bl-none text-[10px] lg:text-sm leading-relaxed w-fit">
										{AIChat?.output?.content}
									</div>
								</div>
							)
						)}
					</div>

					<div className="absolute bottom-2 w-full z-15">
						<div className="flex w-full items-center justify-between md:px-4 py-2">
							<textarea
								ref={textareaRef}
								placeholder="Write a message..."
								className="w-full rounded-full shadow-lg bg-white/80 border-0 outline-none text-gray-700 py-3 px-4 text-sm resize-y max-h-[60px] scrollbar-hide"
								rows={rows}
								autoComplete="off"
								value={isGenerating ? "" : AiInputChat}
								onChange={handleChange}
								onKeyDown={handleKeyPress}
								disabled={isGenerating}
							/>
							<button
								className="bg-white text-black p-[12px] rounded-full mx-4"
								onClick={() => handleSubmitChat(AiInputChat)}
								disabled={isGenerating}
							>
								<IoSend size={20} />
							</button>
							<div className="audio-bttn mr-8 p-1 bg-white rounded-full">
								<AudioRecorder
									onRecordingComplete={(blob) => addAudioElement(blob)}
									recorderControls={recorderControls}
									downloadOnSavePress={false}
									downloadFileExtension="mp3"
									showVisualizer
									onClick={() => onRecord(isRecording)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-none hero_area relative z-0">
					<svg
						className="waves w-full"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 24 150 28"
						preserveAspectRatio="none"
						shapeRendering="auto"
					>
						<defs>
							<path
								id="gentle-wave"
								d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
							/>
						</defs>
						<g className="parallax">
							<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
							<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
							<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
							<use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
						</g>
					</svg>
				</div>
			</div>
		</>

	);
};

export default AitherapistCore;
