

const RouteName = {
    Auth: {
        Login: '/login',
        VerifyOtp: '/VerifyOtp',
        verifyOtpMail: '/verifyotpmail',
        Register: '/Register'
    },
    Common: {
        Aitherapist: '/Aitherapist',
        Group: '/Group',
        Plan: "/Plan",
        Theam: "/Theam",
        Therapist: "/Therapist",
        Appointment_scheduler: "/Appointment_scheduler",
        Join_Group: "/Join_Group",
        Dashboard: '/',
        DigitalNotes: "/DigitalNotes",
        CreateNotes: "/CreateNotes",
        MySession: "/MySession",
        SessionDetails: "/SessionDetails",
        Groupchat: "/Groupchat",
        JoinGroup: "/JoinGroup",
        JoinGroupsession: "/JoinGroupsession",
        Playground: '/Playground',
        Profile: '/Profile',
        Payment: '/Payment',
        Settings: '/Settings',
        UserProfile: '/UserProfile',
        Chat: '/Chat',
        WllbeingTest: '/WllbeingTest',
        LineChart: '/line-chart',
        PersonalityTests: '/PersonalityTests',
        MyTherapist: '/MyTherapist',
        MakePayment: '/MakePayment',
        VideoCall: '/VideoCall',
        Workshop: '/upcomingEvents',
        MoodTrackerReport: '/moodtrackerreport',
        WorkshopDetails: '/workshopdetails',
        groupDetail: '/groupdetail',
        // Footer:'/Footer',
        // Sidebar:'/Sidebar'
        PageNotFound: '*'
    },


}

export default RouteName;