import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const WellnessTagModal = ({ isOpen, onClose, onSubmit }) => {
    const [wellnessTagsList, setWellnessTagsList] = useState([]);
    const [chooseTags, setChooseTags] = useState([]);

    useEffect(() => {
        getWellnessTags();
    }, [isOpen]);

    const getWellnessTags = () => {
        try {
            API.Survey.WellnessTagsList()
                .then((response) => {

                    if (response.data.status === 1) {
                        const checkStatus = response.data.data_all;
                        if (checkStatus.length > 0) {
                            setWellnessTagsList(checkStatus);
                        }
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }
    const insertUserWellnessTag = (id, name) => {
        if (chooseTags.some(tag => tag.tagId === id)) {
            return;
        }
        if (chooseTags.length >= 7) {
            toast.warning("You can only select up to 7 tags.");
            return;
        }
        setChooseTags([...chooseTags, { tagId: id, tagName: name }]);
    };

    const removeTag = (id) => {
        setChooseTags(chooseTags.filter(tag => tag.tagId !== id));
    };

    const handleSubmit = () => {
        onSubmit(chooseTags);
        onClose();
    };
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-3xl max-w-[90%] relative overflow-hidden flex flex-col p-4 bg-gradient-to-b from-white to-[#faf5da] shadow-md border border-[#d8d8d8]" >
                <button
                    className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                <div className='flex flex-col justify-center items-center mt-2'>
                    <span className='text-md md:text-2xl text-black font-bold'>Pick upto 7 Suitable Moods</span>
                </div>
                <div className="p-2 lg:p-4 grid grid-cols-2 md:grid-col-3 lg::grid-cols-8 gap-2 max-h-[calc(100vh-200px)] overflow-y-auto">
                    {wellnessTagsList && wellnessTagsList.length > 0 ? (
                        wellnessTagsList.map((record, index) => {
                            const isSelected = chooseTags.some(tag => tag.tagId === record._id);

                            return (
                                <div
                                    key={index}
                                    className={`text-black text-center rounded-[12px] my-0.5 mx-1 py-1.5 px-2.5 text-xs font-medium shadow-md transition-colors duration-300 cursor-pointer ${isSelected ? 'bg-yellow-400' : 'bg-yellow-50'
                                        } hover:bg-yellow-400 flex items-center`}
                                        onClick={() =>
                                            isSelected
                                                ? removeTag(record._id)
                                                : insertUserWellnessTag(record._id, record.tagName)
                                        }
                                >
                                    <span
                                        className="flex-grow"
                                    >
                                        {record.tagName}
                                    </span>
                                    {isSelected && (
                                        <span
                                            className="ml-2 text-red-600 font-bold cursor-pointer"
                                            onClick={() => removeTag(record._id)}
                                        >
                                            &times;
                                        </span>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No Wellness Tags to display</p>
                    )}
                </div>

                <div className="flex justify-center items-center">
                    <button
                        className="mt-2 w-fit bg-[#F5BD41] border border-[#F5BD41] text-black py-2 px-4 rounded-lg font-semibold align-middle text-center text-xs hover:bg-[#F5BD41]"
                        onClick={handleSubmit}
                    >
                        Done
                    </button>
                </div>

            </div>
        </div>
    )
}

export default WellnessTagModal