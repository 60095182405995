import React from 'react';
import { FaArrowAltCircleRight } from "react-icons/fa";

const DashboardCard = ({ heading, useColor,cardImage, onClick }) => {
    return (
        <div class="max-w-sm rounded-2xl overflow-hidden shadow-md hover:shadow-lg" onClick={onClick}>
            <div class="px-6 pt-4">
                <div class={`font-bold text-sm lg:text-lg mb-2 break-normal`} style={{ color: useColor }}>{heading}</div>
                <div className='flex justify-between items-center'>
                    <FaArrowAltCircleRight color={useColor} size={50}/>
                    <div className='h-28 w-auto object-contain'>
                        <img src={cardImage} className='h-full w-full' />
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DashboardCard