import React from 'react'

const SessionSummaryCard = ({ SessionDetails, onClick }) => {
    console.log('Data', SessionDetails)
    const date = new Date(SessionDetails.timeend);
    const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    const dayOfMonth = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "short" })
    return (
        <div class="w-full p-3 bg-[#FBFAF5]  rounded-2xl shadow-md hover:bg-gray-100 mb-4 border border-[#CBD5E1] cursor-pointer" onClick={onClick}>
            <div className='flex flex-col md:flex-row w-full justify-between items-center'>
                <div class="w-20 h-auto rounded-2xl border shadow-lg overflow-hidden text-center bg-white hidden md:block">
                    <div class="bg-blue-500 text-white font-bold pt-1">
                        {month}
                    </div>
                    <div class="flex flex-col items-center justify-center flex-1 pt-3 text-black">
                        <p class="text-lg font-bold mb-1" style={{ lineHeight: '5px' }}>{dayOfMonth}</p>
                        <p class="text-xs text-gray-500 pt-1 pb-2 mb-0">{day}</p>
                    </div>
                </div>
                <div className='flex justify-center items-center md:justify-start md:items-start flex-col flex-grow px-4'>
                    <p class="text-md font-semibold mb-2 text-center md:text-left">Summary of session with {SessionDetails.theropis[0].fullname}</p>
                    <p class="text-sm text-gray-500 md:hidden">{day + ', ' + dayOfMonth + ' ' + month}</p>
                    <p class="text-sm text-gray-500">{SessionDetails.timestart + ' - ' + time}</p>
                </div>
                <div className='flex justify-center items-center px-2'>
                    <button className="bg-[#95F0A8] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-sm" >
                        Published
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SessionSummaryCard;