import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminRoute from '../Route/RouteDetails';
import API from "../Api/Api";

const TherapistFeedbackProcess = ({ isOpen, onClose, therapistId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const navigate = useNavigate();

  const handleRatingChange = (e) => {
    setRating(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!rating || !comment.trim()) {
      toast.warning('Please provide feedback');
      return;
    }

    API.TherapistFeedback.SubmitTherapistFeedback({
      data: {
        therapistId: therapistId,
        rating: rating,
        comment: comment,
      }
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success('Thank you for your feedback');
          navigate(`${AdminRoute.Common.Therapist}`)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="rounded-3xl max-w-[90%] relative overflow-hidden flex flex-col p-4  bg-gradient-to-b from-white to-[#faf5da] shadow-md border border-[#d8d8d8]" >
        <button
          className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
        <div className='flex flex-col justify-center items-center mt-2 p-6'>
          <h2 className="text-center text-2xl font-semibold text-gray-800 mb-6">Leave Your Feedback</h2>
          <div className="flex justify-center mb-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <React.Fragment key={star}>
                <input
                  type="radio"
                  id={`star${star}`}
                  name="rating"
                  value={star}
                  checked={rating === `${star}`}
                  onChange={handleRatingChange}
                  className="hidden"
                />
                <label
                  htmlFor={`star${star}`}
                  className={`text-[60px] cursor-pointer ${rating >= star ? 'text-yellow-400' : 'text-gray-300'
                    } transition-colors duration-200 `}
                >
                  &#9733;
                </label>
              </React.Fragment>
            ))}
          </div>

          <textarea
            value={comment}
            onChange={handleCommentChange}
            placeholder="Write your comments here..."
            className="w-full h-40 p-3 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#F5BD41] mb-4"
          />
        </div>
        <div className='flex justify-between items-center md:px-2'>
          <button
            className="bg-gradient-to-r from-[#ffdf07] to-[#8bed93] text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs md:text-sm shadow "
            onClick={() => navigate(`${AdminRoute.Common.Therapist}`)}
          >
            SKIP
          </button>
          <button
            className="bg-white shadow text-black py-2 px-4 m-1 rounded-lg font-semibold align-middle text-center text-xs " style={{ border: '1px solid #ffdf07' }}
            onClick={handleSubmit}
          >
            Submit
          </button>


        </div>
      </div>
    </div>
  )
}

export default TherapistFeedbackProcess