import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../Common/Sidebar';
import Header from '../Common/Header';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import { useNavigate } from "react-router-dom";
import SessionBg from "../../Assets/Images/Sessions/session.png";
import preloader from '../../Assets/Images/Flip-Flop.gif';
import SessionCard from './SCard';
import { CiSquarePlus } from "react-icons/ci";
import SessionSummaryCard from './SessionSummaryCard';
import SessionBg1 from "../../Assets/Images/Sessions/SessionSummary.png";
import SessionBg2 from "../../Assets/Images/Sessions/SessionSummaryDetails.png";
import SessionDetailsCard from './SessionDetailsCard';
import { CiLock } from "react-icons/ci";
import { PlanContext } from "../../ContextApi/PlanContext";
import AppointmentModal from '../AppointmentModal';
import { toast } from 'react-toastify';
import SessionPaymentModal from '../SessionPaymentModal';
import TherapistFeedbackProcess from '../TherapistFeedbackProcess';

const MySession = () => {
  const tabs = [
    { name: 'Upcoming Sessions', value: 0 },
    { name: 'Sessions Summary', value: 1 },
  ];
  const navigate = useNavigate();
  const [sumarySessionList, setSumarySessionList] = useState([]);
  const [upcomingSessionList, setUpcomingSessionList] = useState([]);
  const [loadSumaryList, setLoadSumaryList] = useState(false);
  const [loadUpcomingList, setLoadUpcomingList] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]?.value);
  const [loading, setLoading] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(false);
  const [sessionDetailsId, setSessionDetailsId] = useState([]);
  const { planDetails } = useContext(PlanContext);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [therapistId, setTherapistId] = useState('');
  const [takeFeedbackModal, setTakeFeedbackModal] = useState(false);
  const [showChangeTherapist, setShowChangeTherapist] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);


  useEffect(() => {
    getSumarySessionList();
    getUpcomingSessionList();
    // getMyTherapist();

  }, [appointmentModalOpen, activeTab])

  const getMyTherapist = () => {
    setLoadSumaryList(true)
    try {
      API.MyThreapist.CheckMyTherapist().then((response) => {
        if (response.data.status === 1) {
          console.log('getMyTherapist', response);
          setTherapistId(response?.data?.data?.theropistId);
          setAppointmentModalOpen(true);
          setShowChangeTherapist(true);
        } else if (response.data.status === 0) {
          navigate(`${AdminRoute.Common.Therapist}`);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadSumaryList(false)
    }
  }
  // get summary list
  const getSumarySessionList = () => {
    setLoadSumaryList(true)
    try {
      API.Session.sumarySessionList().then((response) => {
        if (response.status === 200) {
          setSumarySessionList(response?.data?.data_all);
          setLoadSumaryList(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoadSumaryList(false)
    }
  }

  const getUpcomingSessionList = () => {
    setLoadUpcomingList(true)
    try {
      API.Session.upcomingSessionList().then((response) => {
        if (response.status === 200) {
          setUpcomingSessionList(response?.data?.data_all);
          setLoadUpcomingList(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoadUpcomingList(false)
    }
  }

  const handleReshedule = (data) => {
    console.log('resheduleData', data);
    const currentTime = new Date();
    const date = new Date(data.timeend);
    const timeDifference = (date - currentTime) / (1000 * 60 * 60);
    if (timeDifference > 2) {
      sessionStorage.setItem('ResheduleAppointmentId', data._id);
      setTherapistId(data.theropistId);
      setAppointmentModalOpen(true);
    } else {
      toast.info('You can reschedule up to 1 hour before your session');
    }


    // navigate(
    //   `../${AdminRoute?.Common?.Therapist}`
    // )

  }

  const handleSessionDetails = (val) => {
    navigate(`../${AdminRoute?.Common?.SessionDetails}?id=${val._id}`);
  }

  const handleSessionSummary = (summaryId) => {
    setSessionDetails(true);
    setSessionDetailsId(summaryId);

  }


  const handleModalClose = () => {
    setAppointmentModalOpen(false);
    sessionStorage.setItem("ResheduleAppointmentId", '');
    setShowChangeTherapist(false);
  }

  const handleFeedbackProcess = () => {
    setTakeFeedbackModal(true);
    setAppointmentModalOpen(false);
  }

  const handleSessionPaymentModal = () => {
    setShowPaymentModal(false);
    setAppointmentModalOpen(true);
  }

  return (
    // <div className="d-flex h-dashboard">
    //   <Sidebar />
    //   <div className='toggleIcon'>
    //     <div className="container-fluid">
    //       <div className="row list">
    //         <div className="nav-wrap">
    //           <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
    //             <div className='w-100 h-head'>
    //               <h2 className='heading  mt-1 mb-1'>My Sessions</h2>
    //               <Button type="submit" className="create-new_notes button_common notes-btn transparent-btn"><NavLink to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}>Book a Sessions </NavLink></Button>
    //             </div>
    //             <Header />
    //           </nav>
    //         </div>
    //         <div className="col-12">
    //           <Button type="submit" className="create-new_notes button_common notes-btn transparent-btn d-block d-md-none my-2 me-0"><NavLink to={`${AdminRoute?.Common?.Appointment_scheduler}/?key=true`}>Book a Sessions </NavLink></Button>
    //         </div>
    //         <div className="col-md-12 mb-2 list-item v-height sessions-v-height">
    //           <div className="sessions-tabs">
    //             <Tabs
    //               defaultActiveKey="home"
    //               id="uncontrolled-tab-example"
    //               className="mb-3 ps-0"
    //             >
    //               <Tab eventKey="home" title="Upcoming Sessions">
    //                 <p className='row sessions-text ps-0'>Here is a detail list of all your upcoming and past sessions.</p>
    //                 {/* <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div>*/}
    //                 <div className="d-flex justify-content-around">
    //                   <ul className="auto-grid px-0 mx-0 my-3 w-100">
    //                     {
    //                       loadUpcomingList ? <div className="container_loader"></div> :
    //                         upcomingSessionList?.length === 0 ? <span>Data not found</span> :
    //                           upcomingSessionList?.map((val, i) => {
    //                             return (
    //                               <li key={i}>
    //                                 <div className="slot-container inner-div">
    //                                   <div className="slot-time">
    //                                     <div className="slot">{val?.timestart}</div>
    //                                     <div className='text-div-sessions'>
    //                                       <div className="slot-div-date">{moment(val?.date).format("DD-MM-YYYY")}</div>
    //                                       <div className="slot-div-name">{val?.theropis[0]?.fullname}</div>
    //                                     </div>
    //                                     <div className="clock">
    //                                       <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                         <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                                       </svg>
    //                                     </div>
    //                                   </div>

    //                                 </div>
    //                               </li>
    //                             )
    //                           })
    //                     }
    //                   </ul>
    //                 </div>

    //               </Tab>
    //               <Tab eventKey="profile" title="Sessions Summary">
    //                 <div className="d-flex justify-content-around">
    //                   <ul className="auto-grid">
    //                     {
    //                       loadSumaryList ? <div className="container_loader"></div> :
    //                         sumarySessionList?.length === 0 ? <span>Data not found</span> :
    //                           sumarySessionList?.map((val) => {
    //                             return (
    //                               <li key={val?._id}>
    //                                 <div className="slot-container inner-div cursor_pointer" onClick={() => handleSessionDetails(val)}>
    //                                   <div className="slot-time">
    //                                     <div className="slot">{val?.timestart}</div>
    //                                     <div className='text-div-sessions'>
    //                                       <div className="slot-div-date">{moment(val?.date).format("DD-MM-YYYY")}</div>
    //                                       <div className="slot-div-name">{val?.theropis[0]?.fullname}</div>
    //                                     </div>
    //                                     <div className="clock py-4">
    //                                       <i className="fa fa-chevron-right"></i>
    //                                     </div>
    //                                   </div>

    //                                 </div>
    //                               </li>
    //                             )
    //                           })
    //                     }
    //                   </ul>
    //                 </div>
    //                 {/*
    //                <div className='p-session'>
    //                   <div className='d-flex justify-content-around'><span className='badge-text'>June 2023</span>  </div>

    //                   <div className="d-flex justify-content-around">
    //                     <ul className="auto-grid">
    //                       <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>
    //                       <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>  <li>
    //                         <div className="slot-container inner-div">
    //                           <div className="slot-time">
    //                             <div className="slot">9:00 | AM</div>
    //                             <div className='text-div-sessions'>
    //                               <div className="slot-div-date"> 04 March 2024</div>
    //                               <div className="slot-div-name">Jaywardhan Mane</div>
    //                             </div>
    //                             <div className="clock">
    //                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
    //                                 <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z"></path>
    //                               </svg>
    //                             </div>
    //                           </div>

    //                         </div>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //               */}
    //               </Tab>
    //             </Tabs>
    //           </div>
    //         </div>
    //       </div>
    //       <Footer />
    //     </div>
    //   </div>
    // </div >
    <div className="grid grid-rows-[auto_1fr_auto] bg-white mx-0 w-full h-screen">
      <Sidebar />
      <div className="flex-none bg-white">
        <Header />
      </div>
      <div className="row-span-1 flex flex-col bg-white lg:ml-[8%] md:ml-[14%] md:mr-[4%] md:my-8 overflow-hidden">
        <div className='flex flex-col justify-start items-start mt-2'>
          <div className='flex justify-between items-center w-full px-4 '>
            <h2 className='text-black font-semibold text-lg md:text-3xl mt-2 md:mt-0'>SESSION</h2>
            <button className="mt-2 bg-[#F5BD41] text-white py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-xs md:text-sm" onClick={() => getMyTherapist()}>
              <div className='flex justify-center items-center'>
                <CiSquarePlus className='mr-2' size={20} /> Book a session
              </div>
            </button>
          </div>
          <ul className="md:flex flex-wrap justify-center items-center mt-2 hidden">
            {tabs.map((tab) => (
              <li key={tab.value} className="mr-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(tab.value);
                  }}
                  className={`relative inline-block p-2 rounded-t-lg text-lg outline-none ${activeTab === tab.value
                    ? 'text-black border-b-2 border-[#F5BD41]'
                    : 'text-gray-600'
                    }`}
                >
                  {tab.name}
                  <span
                    className={`absolute bottom-0 left-0 w-full h-[2px] outline-none ${activeTab === tab.value
                      ? 'bg-[#F5BD41]'
                      : 'bg-transparent hover:bg-[#F5BD41]'
                      }`}
                  />
                </button>
              </li>
            ))}
          </ul>
          <ul className="flex flex-wrap justify-center items-center mt-4 md:hidden">
            {[{ name: 'Upcoming', value: 0 },
            { name: 'Summary', value: 1 }].map((tab) => (
              <li key={tab.value} className="mr-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(tab.value);
                  }}
                  className={`relative inline-block p-2 rounded-t-lg text-lg outline-none ${activeTab === tab.value
                    ? 'text-black border-b-2 border-[#F5BD41]'
                    : 'text-gray-600'
                    }`}
                >
                  {tab.name}
                  <span
                    className={`absolute bottom-0 left-0 w-full h-[2px] outline-none ${activeTab === tab.value
                      ? 'bg-[#F5BD41]'
                      : 'bg-transparent hover:bg-[#F5BD41]'
                      }`}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full h-full mr-4 z-1 flex" >
          <div className='w-full lg:w-3/5'>
            {activeTab === 0 && (
              <div className='w-full flex flex-col max-h-[calc(100vh-300px)] overflow-x-hidden overflow-y-auto scrollbar-hide rounded-3xl p-4 radialGradient'>
                {loading ? (<div className='w-full flex justify-center items-center'>
                  <div className='h-10 w-10 rounded-full'>
                    <img src={preloader} className='h-full w-full object-cover' />
                  </div>
                </div>) : (upcomingSessionList.length > 0 ? (
                  <div className='flex flex-col'>
                    {upcomingSessionList.map((data, index) => (
                      <SessionCard key={index} SessionDetails={data} onClick={() => handleReshedule(data)} />
                    ))}
                  </div>
                ) : (<div className='flex justify-center items-center'>
                  <p className='text-sm text-black font-semibold'> No sessions Found</p>
                </div>))}
              </div>
            )}
            {activeTab === 1 && (
              <div className="w-full flex flex-col max-h-[calc(100vh-300px)] overflow-x-hidden overflow-y-auto scrollbar-hide rounded-3xl p-4 radialGradient">
                {loading ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="h-10 w-10 rounded-full">
                      <img src={preloader} className="h-full w-full object-cover" alt="Loading..." />
                    </div>
                  </div>
                ) : sessionDetails ? (
                  // <SessionDetailsCard
                  // sessionDetailsId={sessionDetailsId}
                  //   />
                  'No Session Summary Found'
                ) : sumarySessionList.length > 0 ? (
                  <div className="flex flex-col">
                    {sumarySessionList.map((data, index) => (
                      <SessionSummaryCard
                        key={index}
                        SessionDetails={data}
                        onClick={() => handleSessionSummary(data._id)}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <p className="text-sm text-black font-semibold">No sessions Summary Found</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='w-2/5 hidden lg:flex' >
            <img src={activeTab === 0 ? SessionBg : activeTab === 1 ? SessionBg1 : SessionBg} alt="Session Background" className='object-scale-down h-full w-full' />
          </div>
        </div>
      </div>

      {/* Footer (Waves) */}
      <div className="flex-none hero_area relative z-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div>
      <TherapistFeedbackProcess isOpen={takeFeedbackModal} onClose={() => setTakeFeedbackModal(false)} therapistId={therapistId}/>
      <SessionPaymentModal isOpen={showPaymentModal} onClose={() => setShowPaymentModal(false)} paymentSuccess={handleSessionPaymentModal}/>
      <AppointmentModal isOpen={appointmentModalOpen} onClose={handleModalClose} therapistId={therapistId} showChangeTherapist={showChangeTherapist} takeFeedback={handleFeedbackProcess} askPaymentModal={() => setShowPaymentModal(true)} />
    </div>
  );
}

export default MySession;
