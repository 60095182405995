// App.js
import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import PersonalityTests from "../../Assets/Images/personality-tests.jpg"
import WellBeingTest from "../../Assets/Images/well-being-test.jpg"
import LineChart from "../../Assets/Images/line-chart.jpg";
import RadarChart from '../Common/RadarChart';
import API from '../../Api/Api';
import { getInitialsLetters } from '../Common/Functions';
import WellCards from './WellCards';
import img1 from "../../Assets/Images/profile/pt.png";
import img2 from "../../Assets/Images/profile/wbt.png";
import img3 from "../../Assets/Images/profile/mag.png";
import profileBg from "../../Assets/Images/profile/profilebg.png";
import defaultUser from "../../Assets/Images/defaultUser.png";
import baseApi from "../../Api/config";
import { toast } from "react-toastify";
import { PlanContext } from "../../ContextApi/PlanContext";


function Profile() {
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState({});
  const [GraphOneData, setGraphOneData] = useState({});
  const [GraphTwoData, setGraphTwoData] = useState({});
  const [GraphThreeData, setGraphThreeData] = useState({});
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    last_name: "",
    birthDate: "",
    profileImage: "",
    email: '',
    phone: '',
    designation: '',
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const { planDetails } = useContext(PlanContext);
  

  useEffect(() => {
    if (UserData.userdata) {
      setFormData({
        ...formData,
        name: UserData.userdata.name,
        last_name: UserData.userdata.last_name,
        birthDate: UserData.userdata.dob.split("T")[0],
        profileImage: UserData.userdata.image,
        email: UserData.userdata.email,
        phone: UserData.userdata.phone,
      })
    }
  }, [edit])
  console.log('UserData', UserData);

  const handleEdit = (e) => {
    e.preventDefault();
    setEdit(true);
  }

  const dateFormater = (inputDate) => {
    const date = new Date(inputDate);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);
  
    return formattedDate;
  };

  const Update_userValidate = (values) => {
    const errors = {};
    if (!values?.name) {
      errors.firstName = "First name is required";
    }
    if (!values?.last_name) {
      errors.lastName = "Last name is required";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // API.
      try {
        API.Auth.UpdateProfileAPI({
          data: {
            name: formData?.name,
            last_name: formData?.last_name,
            dob: formData?.birthDate,
            role: "Client",
            image: formData?.profileImage
          },
          UserId: UserData?.userdata?._id,


        }).then((response) => {
          console.log('edit', response);
          UserData.userdata.name = formData.name
          UserData.userdata.last_name = formData?.last_name
          UserData.userdata.dob = formData?.birthDate
          UserData.userdata.image = formData?.profileImage
          if (response?.data?.message === "Profile successfully updated.") {
            toast.success('Profile updated successfully');
            setEdit(false);
            localStorage.setItem(
              "ClientUserData",
              JSON.stringify(UserData)
            );
          }
          setIsSubmit(false)
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(Update_userValidate(formData));
    setIsSubmit(true);
  };

  const disableFutureDate = () => {
    const today = new Date().toISOString().split('T')[0];
    // const dd = String(today.getDate()).padStart(2, "0");
    // const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    // const yyyy = today.getFullYear();
    return today;
  }


  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    // if (
    //   e.target.files[0].type.includes("image") &&
    //   e.target.files[0].size < 4000000
    // )
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };


  const addImage = (image) => {
    setFormData({
      ...formData,
      profileImage: `${baseApi.baseurl}images/${image}`
    })
  };

  const config = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let config1 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let config2 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      labels: [
        "Engagement",
        "Leadership",
        "Burnout"
      ],
    },
    series: [
      {
        name: "OHEP Index",
        data: [
          4.9,
          3.9,
          3.6
        ]
      }
    ]
  };

  let state = {
    series: [
      {
        name: "Joy",
        data: [28, 29, 33, 36, 32, 32, 33]
      },
      {
        name: "fodIntake",
        data: [12, 11, 14, 18, 17, 13, 13]
      },
      {
        name: "drive",
        data: [28, 19, 33, 20, 32, 32, 33]
      },
      {
        name: "sleep",
        data: [12, 11, 10, 18, 19, 13, 13]
      },
      {
        name: "stress",
        data: [28, 19, 33, 20, 32, 32, 33]
      },
      {
        name: "activity",
        data: [12, 11, 10, 18, 19, 13, 13]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#77B6EA', '#545454', '#F7E7CE', '#FF8F89', '#49F5FF', '#964B00'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2, // Adjust the line width here
      },
      // title: {
      //   text: 'Average High & Low Temperature',
      //   align: 'left',
      // },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        title: {
          text: 'Month',
        },
      },
      yaxis: {
        title: {
          text: 'Temperature',
        },
        min: 5,
        max: 40,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      }, setY: -25,
      offsetX: -5
    },
  }


  useEffect(() => {
    setLoading(true)
    try {
      API.Survey.AnswerSurvey()
        .then((response) => {
          if (response.data.status === 1) {
            if (response?.data.graph1.length) {
              config.options.labels = response?.data?.graph1[0]?.keys
              config.series[0].data = response?.data?.graph1[0]?.values
              setGraphOneData(config)
            }
            if (response?.data.graph2.length) {
              config1.options.labels = Object.keys(response?.data?.graph2[0])
              config1.series[0].data = Object.values(response?.data?.graph2[0])
              setGraphTwoData(config1)
            }
            if (response.data.graph3.length) {
              state.series = response?.data?.graph3[0]
              // config2.series[0].data = response.data.graph3[0].values
              setGraphThreeData(state)
            }
            setGraphData(response?.data);
          }
          setLoading(false)
        });
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }, [])

  const handleGraphValidation = (graph) => {
    API.Survey.GetTimit()
      .then((response) => {
        if (!response?.data?.personality) {
          if (graph === "PersonalityTests") {
            navigate(`../${AdminRoute?.Common?.PersonalityTests}`)
          }
        }
        if (!response.data.wellbeing) {
          if (graph === "WellBeingTest") {
            navigate(`../${AdminRoute?.Common?.WllbeingTest}`)
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  }


  console.log('planDetails', planDetails);

  //jsx
  return (
    <>
      {/* <div className="d-flex h-dashboard profile">
        <Sidebar />
        <div className='toggleIcon'>
          <div className="container-fluid">

            <div className="row">
              <div className="nav-wrap">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <h2 className='heading  mt-1 mb-1'>My Profile</h2>
                  <Header />
                </nav>
              </div>

              <div className="col-12 mb-2 profile-v-height">
                <div className='row'>
                  <div className="col-md-8 b-0">
                    <div className="card-body payment-card-body">
                      <div className="row">
                        <h3 className="secheading mt-3 mb-2">Personal Details</h3>
                        <p className='mb-3 text-muted'>Below are your profile details</p>

                        <div className='col-12'>
                          <div className='profile-image-box d-flex justify-content-center align-items-center mb-3'>
                            {UserData?.userdata?.image ?
                              <img src={UserData?.userdata?.image} alt="profile"></img>
                              :
                              <p className='default-text text-center'>{getInitialsLetters(UserData?.userdata?.name, UserData?.userdata?.last_name)}</p>
                            }
                          </div>
                        </div>
                        <div className="col-md-6 left-profile-div pl-3">

                          <div className="col-sm-12 mb-3">
                            <span className="">First Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.name} </span>
                          </div>
                          <div className="col-sm-12 mb-3">
                            <span className="">Last Name: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.last_name} </span>
                          </div>

                          <div className="col-sm-12 mb-3">
                            <span className="">Date of birth: </span><span className="col-sm-9 fw-bold">{moment(UserData?.userdata?.dob).format("LL")}</span>
                          </div>
                        </div>

                        <div className="col-md-6 right-profile-div">
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <span className="">E-Mail Id:  </span><span className="col-sm-y fw-bold"> {UserData?.userdata?.email}</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <span className="">Mobile No.: </span><span className="col-sm-9 fw-bold"> {UserData?.userdata?.phone} </span>
                            </div>
                          </div>

                          <div className="row d-flex justify-content-end">
                            <div className="col-sm-12 w-50">
                              <Button type="submit" className="transparent-border-btn mb-0.">
                                <NavLink to={`${AdminRoute?.Common?.UserProfile}`}> Edit Profile </NavLink>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <h3 className="secheading mt-3 mb-3">Wellness Map</h3>
                    {loading ?
                      <div className="container_loader my-5"></div>
                      :
                      <div className="wrapper-play mb-0 mb-md-4">
                        <div className="box">
                          <div className="card-body" onClick={() => handleGraphValidation("PersonalityTests")}>
                            {GraphData?.graph1?.length && Object.keys(GraphData?.graph1[0]).length !== 0 ?
                              <RadarChart options={GraphOneData.options} series={GraphOneData.series} type="radar" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={PersonalityTests} alt="PersonalityTests" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Personality Tests</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body" onClick={() => handleGraphValidation("WellBeingTest")}>
                            {GraphData?.graph2?.length && Object.keys(GraphTwoData)?.length !== 0 ?
                              <RadarChart options={GraphTwoData?.options} series={GraphTwoData?.series} type="radar" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={WellBeingTest} alt="WellBeingTest" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Well being Test</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                        <div className="box">
                          <div className="card-body">
                            {GraphData?.graph3?.length && Object.keys(GraphThreeData).length !== 0 ?
                              <RadarChart options={GraphThreeData?.options} series={GraphThreeData?.series} type="line" />
                              :
                              <div className="playground-img-div bg-grey mb-3">
                                <span className="img">
                                  <img src={LineChart} alt="LineChart" />
                                </span>
                              </div>
                            }
                            <div className="support-div-text">
                              <h3 className="card-heading text-left">Line Chart</h3>
                              <p className="card-text mb-2 text-muted published-date"> Published Date: 01 Sept, 2023</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div> */}
      {/* <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
        <div className="flex-grow bg-white m-0 w-full p-0" >
          <Header />
          <Sidebar />
          <div className="flex flex-grow md:ml-[8%] h-full md:mr-[3%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 bg-[#F5F6FB80] border border-[#E7F0FC]" >
            <div className="flex w-full bg-transparent rounded-3xl">
              <div className='w-2/3 flex flex-col'>
                <div className='w-full bg-white shadow-md rounded-2xl mb-4 p-4'>
                  <div className='flex-col flex justify-start items-start'>
                    <div className='flex justify-center items-center mb-2'>
                      <div className='h-20 w-20 mr-4 rounded-full'>
                        <img src={UserData?.userdata?.image ? UserData?.userdata?.image : defaultUser} className='w-full h-full rounded-full' />
                      </div>
                      <span className='text-3xl font-bold text-black'>{UserData?.userdata?.name}{' '}{UserData?.userdata?.last_name}</span>
                    </div>
                    <form className='mt-2 w-full'>
                      <div className="grid grid-cols-3 gap-4">
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">First Name</label>
                          <input type="text" name="name" value={formData.name} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Last Name</label>
                          <input type="text" name="last_name" value={formData.last_name} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">DOB</label>
                          <input type="date" name='birthDate' value={formData.birthDate} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Email</label>
                          <input type="email" value={formData.email} readOnly={edit} disabled={true} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Contact Number</label>
                          <input type="tel" value={formData.phone} readOnly={edit} disabled={true} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                      </div>
                      <div className='w-full mt-4'>
                        <div className="flex justify-end">
                          <div className='flex justify-between gap-4'>
                            {!edit ?
                              (<button type="button" className="px-4 py-2 text-black font-semibold rounded-lg shadow focus:outline-none" onClick={() => navigate(`${AdminRoute?.Common?.Dashboard}`)}>
                                Back
                              </button>) : (<button type="button" className="px-4 py-2 text-black font-semibold rounded-lg shadow focus:outline-none" onClick={() => setEdit(false)}>
                                Cancel
                              </button>)}
                            {!edit ? (<button type="button" className="px-4 py-2 bg-[#F5BD41] text-black font-semibold rounded-lg shadow  focus:outline-none" onClick={(e) => handleEdit(e)}>
                              Edit
                            </button>) : (<button type="submit" className="px-4 py-2 bg-[#F5BD41] text-black font-semibold rounded-lg shadow  focus:outline-none" onClick={(e) =>  handleSubmit(e)}>
                              Save
                            </button>)}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='w-full bg-white shadow-md rounded-2xl flex flex-col justify-center items-center p-2 pb-3'>
                  <span className='text-2xl text-black font-bold mb-2'>Plan Details</span>
                  <div className='flex justify-between items-center mt-2 gap-28'>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Current plan</span>
                      <span className='text-black text-lg font-normal'>{planDetails.name}</span>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Activated on</span>
                      <span className='text-[#7EBA28] text-lg font-normal'>{dateFormater(planDetails.planInfo.startDate)}</span>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Expiring On</span>
                      <span className='text-[#FF7B7B] text-lg font-normal'>{dateFormater(planDetails.planInfo.endDate)}</span>
                    </div>
                    <button type="button" className="px-4 py-2 bg-gradient-to-r from-[#7F88CD] to-[#A15D8E] text-white font-semibold rounded-lg shadow  focus:outline-none" onClick={() => navigate(`${AdminRoute?.Common?.Plan}`)}>
                      UPGRADE
                    </button>
                  </div>
                </div>
              </div>
              <div className='w-1/3 flex-col flex-grow flex justify-center items-center'>
                <span className='text-black text-3xl mb-4 font-semibold'>Map your Wellness</span>
                <div className='grid grid-cols-1'>
                  <WellCards heading={'Personality Test'} imgSrc={img1} /> 
                  <WellCards heading={'Well-being Test'} imgSrc={img2} /> 
                  <WellCards heading={'Mood Tracker Report'} imgSrc={img3}  /> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero_area relative z-0">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>
      </div> */}
      <div className="grid grid-rows-[auto_1fr_auto] bg-white w-full h-screen overflow-hidden">
        <Sidebar />
        <div className="flex-none bg-white">
          <Header />
        </div>
        <div
          className="row-span-1 lg:ml-[8%] md:ml-[14%] md:mr-[3%] md:my-8 overflow-hidden relative z-1 rounded-2xl p-2 bg-[#F5F6FB80] border border-[#E7F0FC] "
        >
          <div className="flex w-full flex-col lg:flex-row overflow-y-auto max-h-[calc(100vh-100px)] scrollbar-hide">
              <div className='w-full lg:w-2/3 flex flex-col mb-2'>
                <div className='w-full bg-white shadow-md rounded-2xl mb-4 p-4'>
                  <div className='flex-col flex justify-start items-start'>
                    <div className='flex justify-center items-center mb-2'>
                      <div className='h-12 w-12 md:h-20 md:w-20 mr-4 rounded-full'>
                        <img src={UserData?.userdata?.image ? UserData?.userdata?.image : defaultUser} className='w-full h-full rounded-full' />
                      </div>
                      <span className='text-sm md:text-3xl font-bold text-black'>{UserData?.userdata?.name}{' '}{UserData?.userdata?.last_name}</span>
                    </div>
                    <form className='mt-2 w-full'>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">First Name</label>
                          <input type="text" name="name" value={formData.name} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Last Name</label>
                          <input type="text" name="last_name" value={formData.last_name} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">DOB</label>
                          <input type="date" name='birthDate' value={formData.birthDate} onChange={(e) => handleChange(e)} disabled={!edit} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Email</label>
                          <input type="email" value={formData.email} readOnly={edit} disabled={true} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-xs font-bold mb-2 ml-[2%]">Contact Number</label>
                          <input type="tel" value={formData.phone} readOnly={edit} disabled={true} className="bg-[#D9D9D947] mb-2 appearance-none border-2 border-[#D9D9D947] rounded-[30px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" />
                        </div>
                      </div>
                      <div className='w-full mt-4'>
                        <div className="flex justify-end">
                          <div className='flex justify-between gap-4'>
                            {!edit ?
                              (<button type="button" className="px-4 py-2 text-black font-semibold rounded-lg shadow focus:outline-none" onClick={() => navigate(`${AdminRoute?.Common?.Dashboard}`)}>
                                Back
                              </button>) : (<button type="button" className="px-4 py-2 text-black font-semibold rounded-lg shadow focus:outline-none" onClick={() => setEdit(false)}>
                                Cancel
                              </button>)}
                            {!edit ? (<button type="button" className="px-4 py-2 bg-[#F5BD41] text-black font-semibold rounded-lg shadow  focus:outline-none" onClick={(e) => handleEdit(e)}>
                              Edit
                            </button>) : (<button type="submit" className="px-4 py-2 bg-[#F5BD41] text-black font-semibold rounded-lg shadow  focus:outline-none" onClick={(e) =>  handleSubmit(e)}>
                              Save
                            </button>)}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='w-full bg-white shadow-md rounded-2xl flex flex-col justify-center items-center p-2 pb-3'>
                  <span className='text-2xl text-black font-bold mb-2'>Plan Details</span>
                  <div className=' grid grid-cols-1 md:grid-cols-4 mt-2 lg:gap-20 md:gap-10'>
                    <div className='flex flex-col justify-center items-center mb-2'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Current plan</span>
                      <span className='text-black text-lg font-normal'>{planDetails.name}</span>
                    </div>
                    <div className='flex flex-col justify-center items-center mb-2'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Activated on</span>
                      <span className='text-[#7EBA28] text-lg font-normal'>{dateFormater(planDetails.planInfo.startDate)}</span>
                    </div>
                    <div className='flex flex-col justify-center items-center mb-2'>
                      <span className='text-[#000000AD] text-sm font-normal mb-2'>Expiring On</span>
                      <span className='text-[#FF7B7B] text-lg font-normal'>{dateFormater(planDetails.planInfo.endDate)}</span>
                    </div>
                    <button type="button" className="px-4 py-2 bg-gradient-to-r from-[#7F88CD] to-[#A15D8E] text-white font-semibold rounded-lg shadow  focus:outline-none" onClick={() => navigate(`${AdminRoute?.Common?.Plan}`)}>
                      UPGRADE
                    </button>
                  </div>
                </div>
              </div>
              <div className='w-full lg:w-1/3 flex-col flex-grow flex justify-center items-center'>
                <span className='text-black text-3xl mb-4 font-semibold'>Map your Wellness</span>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-2 lg:grid-cols-1'>
                  <WellCards heading={'Personality Test'} imgSrc={img1} /> {/* onClick={() => navigate(`${AdminRoute?.Common?.PersonalityTests}`)} */}
                  <WellCards heading={'Well-being Test'} imgSrc={img2} /> {/* onClick={() => navigate(`${AdminRoute?.Common?.WllbeingTest}`)} */}
                  <WellCards heading={'Mood Tracker Report'} imgSrc={img3}  /> {/* onClick={() => navigate(`${AdminRoute?.Common?.MoodTrackerReport}`)} */}
                </div>
              </div>
            </div>
        </div>
        <div className="flex-none hero_area relative z-0">
          <svg
            className="waves w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>
      </div>
    </>

  );
}

export default Profile;
