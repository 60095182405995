import React from 'react'
import defaultUserImage from "../../Assets/Images/defaultUser.png";

const TherapistCard = ({ data, onClick,viewProfile }) => {
  return (
    <div className='w-full flex flex-col bg-white rounded-3xl p-3' style={{ border: '1px solid #F8D68B' }} >
      <div className='flex'>
        <img src={data.image ? data.image : defaultUserImage} className='w-20 h-20 rounded-full' />
        <div className='flex flex-col flex-grow pl-2'>
          <span class="font-bold text-xl text-black mt-1">{data.fullname}</span>
          <span class="font-semibold text-sm text-gray-700 mt-1">Wellness Coach</span>
          <span class="text-xs text-gray-600 mt-1">{data.experience}{'+ years |'} {data.language ? data.language : 'English, Hindi and Kannada'}</span>
        </div>
      </div>
      <div className='mt-4 w-fit pl-2'>
        <div className='bg-[#F8D68B] text-black px-4 py-2 rounded-full text-xs font-bold'>
          {data.experties}
        </div>
      </div>
      <div className='mt-4 mb-2 w-fit pl-4'>
        <span className='text-gray-700 text-sm'>
          {data.qote}
        </span>
      </div>
      <div className='my-2 flex justify-center items-center'>
        <button className='bg-white px-4 py-2 text-black text-sm rounded-full shadow-md mr-4 font-bold' style={{ border: '1px solid #F8D68B' }} onClick={viewProfile}> View Profile</button>
        <button className='bg-[#FFB81D] px-4 py-2 text-black text-sm rounded-full shadow-md font-bold' onClick={onClick}>Schedule Session</button>
      </div>
    </div>
  )
}

export default TherapistCard