import React from 'react';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from "moment";


const SCard = ({ SessionDetails, onClick }) => {
    const navigate = useNavigate();
    const currentTime = moment();
    const startTime = moment.utc(SessionDetails.date).local();
    const endTime = new Date(SessionDetails.timeend);
    const fifteenMinutesBeforeStart = startTime.clone().subtract(15, "minutes");
    const isJoinButtonVisible = currentTime.isBetween(fifteenMinutesBeforeStart, endTime);

    const renderActionButton = () => {
        API.Session.JoinMeeting({
            data: {
                role: "Cmsemployee",
                meetingId: SessionDetails?.mettingname,
            },
        })
            .then((response) => {
                if (response.data.status === 1) {
                    navigate(
                        `../${AdminRoute.Video.VideoCall}?id=${btoa(
                            SessionDetails.meetingtoken
                        )}&name=${SessionDetails.mettingname}`
                    );
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const date = new Date(SessionDetails.timeend);
    const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    const dayOfMonth = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "short" });

    return (
        <div className="w-full p-3 bg-[#FBFAF5] rounded-2xl shadow-md hover:bg-gray-100 mb-4 border border-[#CBD5E1]">
            <div className="flex flex-col md:flex-row w-full justify-between items-center">
                <div className="w-20 h-auto rounded-2xl border shadow-lg overflow-hidden text-center bg-white hidden md:block">
                    <div className="bg-blue-500 text-white font-bold pt-1">{month}</div>
                    <div className="flex flex-col items-center justify-center flex-1 pt-3 text-black">
                        <p className="text-lg font-bold mb-1" style={{ lineHeight: "5px" }}>
                            {dayOfMonth}
                        </p>
                        <p className="text-xs text-gray-500 pt-1 pb-2 mb-0">{day}</p>
                    </div>
                </div>
                <div className="flex justify-center items-center md:justify-start md:items-start flex-col flex-grow px-4">
                    <p className="text-md font-semibold mb-2 text-center md:text-left">
                        Your Upcoming session with {SessionDetails.theropis[0].fullname}
                    </p>
                    <p className="text-sm text-gray-500 md:hidden">
                        {day + ", " + dayOfMonth + " " + month}
                    </p>
                    <p className="text-sm text-gray-500">
                        {SessionDetails.timestart + " - " + time}
                    </p>
                </div>
                <div className="flex justify-center items-center px-2">
                    {isJoinButtonVisible ? (
                        <button
                            className="bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-sm"
                            onClick={() => renderActionButton()}
                        >
                            Join Session
                        </button>
                    ) : (<button
                        className="bg-[#F5BD41] text-black py-2 px-4 rounded-lg shadow-md font-semibold align-middle text-center text-sm"
                        onClick={onClick}
                    >
                        Reschedule
                    </button>)}
                </div>
            </div>
        </div>
    );
};


export default SCard;