import React, { useState, useEffect, useRef } from "react";
import API from "../../Api/Api";
import { socket } from "../../socket";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { formatDateTime, getInitialsLetters } from "../Common/Functions";
import { LuSendHorizonal } from "react-icons/lu";
import {
    get15MinutesBefore,
    get1HourFuture,
    combineDateTime,
} from "../Common/Functions";
import moment from "moment";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import baseApi from "../../Api/config";
import ResourceComponent from "./ResourceComponent";
import ActivityImage from "../../Assets/Images/Groups/Activity.png";
import ResourceImage from "../../Assets/Images/Groups/Resources.png";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import groupBg from "../../Assets/Images/Groups/GroupsBG.png";

const MobileGroupDetailsPage = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [room] = useState("chatmessage");
    const [currMessage, setCurrMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [rows, setRows] = useState(1);
    const [allResourceData, setAllResourceData] = useState([]);
    const [allActivityData, setAllActivityData] = useState([]);
    const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
    const [loadingSubmitAns, setLoadingSubmitAns] = useState(false);
    const chatHistoryRef = useRef(null);
    const { groupId } = useParams();
    const { state } = useLocation();
    const groupType = state?.groupType;

    console.log('groupId', groupId);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabNames = ['Discussion', 'Activity', 'Resources'];

    const navigate = useNavigate();


    useEffect(() => {
        getGropAllChats(groupId);
        getAllEventData(groupId);
        getGropuMedia(groupId);
    }, [groupId]);

    console.log('allActivityData', allActivityData);

    const getAllEventData = (val) => {
        API.Groups.EventList({
            group_id: val,
        })
            .then((response) => {
                if (response?.data?.status === 1) {
                    setAllActivityData(response?.data?.data_all);
                }
            })
            .catch((error) => {
                // setLoadingChat(false);
            });
    };
    const getGropuMedia = (val) => {
        API.Groups.Medialist({
            group_id: val,
        })
            .then((response) => {
                if (response.data.status === 1) {
                    // setAllResourceData(response.data.data_all);
                }
            })
            .catch((error) => {
                // setLoadingChat(false);
            });
    };

    const sendMessage = async () => {
        if (currMessage !== "") {
            const messageData = {
                room_name: room,
                author: `${UserData?.userdata?.name} ${UserData?.userdata?.last_name}`,
                user_id: UserData?.userdata?._id,
                group_id: groupId,
                message: currMessage,
                time: new Date(),
            };
            await socket.emit(room, messageData);
        }
        setCurrMessage("");
    };

    useEffect(() => {
        if (!socket || !room) return;

        const handleData = (data) => {
            if (groupId && groupId === data.group_id) {
                setMessageList((list) => [...list, data]);
            }
        };

        socket.on(room, handleData);

        return () => {
            socket.off(room, handleData);
        };
    }, [socket, room, groupId]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            setRows(1);
            sendMessage(e);
            e.preventDefault();
        } else if (e.key === "Enter" && e.shiftKey) {
            if (rows < 6) {
                setRows(rows + 1);
            }
            const cursorPosition = e.target.selectionStart;
            const newInputValue =
                currMessage.substring(0, cursorPosition) +
                "\n" +
                currMessage.substring(cursorPosition);
            setCurrMessage(newInputValue);
            e.preventDefault();
        }
    };
    const getGropAllChats = (val) => {
        // setLoadingChat(true);
        API.Groups.GroupsChatList({
            userId: UserData?.userdata?._id,
            group_id: groupId,
        })
            .then((response) => {
                if (response?.data?.status === 1) {
                    setMessageList(response?.data?.data_all);
                    // setLoadingChat(false);
                }
            })
            .catch((error) => {
                console.log(error);
                // setLoadingChat(false);
            });
    };

    const handleSubmitAns = (val, option) => {
        if (!loadingSubmitAns) {
            setLoadingSubmitAns(true);
            const requestData = {
                event_id: val?._id,
                created_by: UserData?.userdata?._id,
            };
            requestData[option] = true;
            API.Groups.AnswerEvent({
                data: requestData,
            })
                .then((response) => {
                    if (response?.data?.status === 1) {
                        setLoadingSubmitAns(false);
                        // getAllEventData(val?.group[0]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingSubmitAns(false);
                });
        }
    };



    const renderActionButton = (data) => {
        API.Session.JoinMeeting({
            data: {
                role: "Client",
                meetingId: data?.mettingname,
            },
        })
            .then((response) => {
                if (response.data.status === 1) {
                    navigate(
                        `../${AdminRoute.Common.VideoCall}?id=${btoa(
                            data?.meetingtoken
                        )}&name=${data?.mettingname}`
                    );
                } else {
                    toast.error(response?.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [messageList, room]);
    return (
        <>
            <div className="grid grid-rows-[auto_1fr_auto] bg-white mx-0 w-full h-screen" style={{
                background: `url(${groupBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top right',
                backgroundRepeat: 'no-repeat',
            }}>
                <Sidebar />
                <div className="flex-none bg-white">
                    <Header />
                </div>
                <div className="row-span-1 flex md:ml-[14%] md:mr-[3%] md:my-8 overflow-hidden z-1">
                    <div className={`grid grid-rows-[auto_1fr_auto] w-full rounded-3xl ${activeTab === 0
                        ? groupType === 'focused'
                            ? 'bg-gradient-to-b from-[#FFFFFF] to-[#D7E8FF]'
                            : groupType === 'support'
                                ? 'bg-gradient-to-b from-[#FFFFFF] to-[#D7FFF2]'
                                : 'bg-white'
                        : 'bg-white'
                        }`} >
                        <div className="flex-none px-2 py-2 mb-4">
                            <nav
                                className="relative z-0 flex border rounded-xl overflow-hidden dark:border-neutral-700 outline-none"
                                aria-label="Tabs"
                                role="tablist"
                                aria-orientation="horizontal"
                            >
                                {tabNames.map((tab, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`${activeTab === index ? `text-white ${groupType === 'focused' ? 'bg-[#5FA5F9]' : groupType === 'support' ? 'bg-[#ABDC8C]' : 'bg-white'}` : 'bg-white'
                                            } relative flex-1 border-s py-3 px-4 text-gray-500 ${groupType === 'focused' ? 'hover:text-[#5FA5F9]' : groupType === 'support' ? 'hover:text-[#ABDC8C]' : 'hover:text-gray-700'} text-sm font-medium text-center overflow-hidden outline-none`}
                                        id={`tab-item-${index}`}
                                        aria-selected={activeTab === index}
                                        onClick={() => handleTabClick(index)}
                                        role="tab"
                                    >
                                        {tab}
                                    </button>
                                ))}
                            </nav>

                            <p className="text-[10px] md:text-sm pb-1 text-black  w-full text-center mt-4">
                                {activeTab === 0
                                    ? "Engage in meaningful conversations, sharing experiences, feelings, and insights, fostering a supportive community atmosphere."
                                    : activeTab === 1
                                        ? "Participate in quizzes and polls designed to deepen self-awareness and curated by group moderators."
                                        : activeTab === 2
                                            ? "Access and contribute to a valuable repository of mental health practices and tools, enhancing personal growth and group learning"
                                            : null}
                            </p>
                        </div>
                        <div className="row-span-1 w-full">
                            {activeTab === 0 && (
                                <div className='px-2 overflow-y-auto overflow-x-hidden w-full' >
                                    <div className="w-full">
                                        <ul className="max-h-[calc(100vh-360px)] md:max-h-[calc(100vh-420px)] overflow-y-auto scrollbar-hide w-full" ref={chatHistoryRef}>
                                            {messageList &&
                                                messageList?.map((messageContent, i) => {
                                                    return (
                                                        <li
                                                            key={messageContent?._id}
                                                            className={`w-full flex mb-2 ${UserData?.userdata?._id === messageContent.user_id
                                                                ? "justify-end"
                                                                : "justify-start"
                                                                }`}
                                                        >
                                                            <div className={`flex flex-col  ${UserData?.userdata?._id === messageContent.user_id ? "justify-end items-end" : "justify-start items-start"}`}>
                                                                <div className={` border-1 border-[#C7C6C6] text-gray-800 rounded-full pt-3 px-4 mb-1 lg:text-sm leading-relaxed relative text-left w-fit ${UserData?.userdata?._id === messageContent.user_id ? "rounded-br-none bg-[#ABDC8C]" : "rounded-bl-none bg-[#E4FFDC]"} `}>
                                                                    {UserData?.userdata?._id === messageContent.user_id ? (null) : (<h6 className="flex items-center justify-start mb-2">
                                                                        {messageContent?.author
                                                                            ? messageContent?.author
                                                                            : messageContent?.user[0]?.fullname
                                                                                ? messageContent?.user[0]?.fullname
                                                                                : `${messageContent?.user[0]?.name}`}
                                                                    </h6>)}
                                                                    <p className="text-gray-950 text-sm">{messageContent.message}</p>
                                                                </div>
                                                                <div className="text-xs text-gray-500 mt-1 ">
                                                                    {messageContent?.createdAt
                                                                        ? formatDateTime(messageContent?.createdAt)
                                                                        : formatDateTime(new Date())}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                    <div className="relative w-full scrollbar-hide">
                                        <textarea
                                            className="w-full rounded-full bg-white/80 border-0 outline-none text-gray-700 py-3 text-sm resize-y max-h-[100px] pr-20 pl-4 scrollbar-hide"
                                            placeholder="Write a message..."
                                            tabIndex="0"
                                            rows={rows}
                                            cols={50}
                                            value={currMessage}
                                            onChange={(event) => setCurrMessage(event.target.value)}
                                            onKeyDown={handleKeyPress}
                                        />
                                        <button
                                            className="absolute right-1 top-1/2 transform -translate-y-1/2 text-black px-4 rounded flex items-center"
                                            title="Send message"
                                            tabIndex="0"
                                            onClick={sendMessage}
                                        >
                                            <LuSendHorizonal size={25} />
                                        </button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 1 && (
                                <div className="flex justify-end w-full">
                                    <ul className="flex flex-col overflow-y-auto scrollbar-hide">
                                        {allActivityData.length === 0 ? (
                                            <span className="font-bold text-sm"></span>
                                        ) : (
                                            allActivityData?.map((val) => {
                                                const date =
                                                    val?.data?.startDate &&
                                                        val?.data?.startTime
                                                        ? combineDateTime(
                                                            val?.data?.startDate,
                                                            val?.data?.startTime
                                                        )
                                                        : new Date(); // shedual time zone
                                                const currdate = new Date();
                                                const beforeminute = date
                                                    ? new Date(get15MinutesBefore(date))
                                                    : new Date(); // get 15 min before time
                                                const endDate =
                                                    date && new Date(get1HourFuture(date));
                                                const startDateResult =
                                                    currdate > beforeminute; // true so sesstion btn show
                                                const endDateResult = currdate < endDate; // true seesion btn show

                                                // Provided time (15 minutes before the given date)
                                                const providedTime = moment(
                                                    get15MinutesBefore(date)
                                                ).toISOString();

                                                // Given ISO 8601 date and time
                                                const isoDate = val?.data?.startDate;
                                                const isoTime = val?.data?.startTime;

                                                // Combine the date and time
                                                const combinedDateTime = `${isoDate?.split("T")[0]
                                                    } ${isoTime}`;

                                                // Convert using moment.js
                                                const formattedStartDateTime =
                                                    moment(combinedDateTime);

                                                // Given ISO 8601 date and time
                                                const isoEndDate = val?.data?.endDate;
                                                const isoEndTime = val?.data?.endTime;

                                                // Combine the date and time
                                                const combinedEndDateTime = `${isoEndDate?.split("T")[0]
                                                    } ${isoEndTime}`;

                                                // Convert using moment.js
                                                const formattedEndDateTime =
                                                    moment(combinedEndDateTime);

                                                // Current time
                                                const currentTime = moment();

                                                // End time
                                                const endTime = moment(endDate);



                                                // Convert the ISO strings back to Moment objects for comparison
                                                const currentTimeMoment =
                                                    moment(currentTime);
                                                const providedTimeMoment =
                                                    moment(providedTime);
                                                const endTimeMoment = moment(endTime);

                                                // Check if the current time is between the provided time and end time
                                                const isBetween =
                                                    currentTimeMoment.isBetween(
                                                        formattedStartDateTime,
                                                        formattedEndDateTime
                                                    );

                                                console.log('Value', val);

                                                return (
                                                    <></>
                                                    // <React.Fragment key={val.data._id} >
                                                    //     {val.data.type?.toLowerCase() ===
                                                    //         "poll" && (
                                                    //             <li className="max-w-sm flex flex-col bg-[#FFFFFF] border border-[#EBE8E8] shadow-sm py-3 px-4 rounded-2xl mb-4 min-w-[300px]">
                                                    //                 <h2 className="flex w-full justify-center item-center">
                                                    //                     <span className="text-3xl text-[#6F6CFF] font-bold">Poll</span>
                                                    //                 </h2>
                                                    //                 <h4 className="text-lg text-black font-semibold">{val.data?.question}</h4>

                                                    //                 {val?.isresSubmited ? (
                                                    //                     <>
                                                    //                         <div className="mx-0 mb-3 relative">
                                                    //                             <div
                                                    //                                 className={` h-10 rounded-2xl bg-[#F8D68B] border border-[#F8D68B]
                                                    //                             }`}
                                                    //                                 role="progressbar"
                                                    //                                 style={{
                                                    //                                     width: `${((val?.score?.option1 || 0) / (val?.totaloption?.total || 1)) * 100}%`,
                                                    //                                 }}
                                                    //                             >
                                                    //                                 <p className="ps-3">{val.data.option1}</p>
                                                    //                                 <p className="pe-3 position-absolute progress-percent">
                                                    //                                     {val?.totaloption?.total
                                                    //                                         ? parseInt((100 * (val?.score?.option1 || 0)) / val?.totaloption?.total)
                                                    //                                         : 0}
                                                    //                                     %
                                                    //                                 </p>
                                                    //                             </div>
                                                    //                         </div>

                                                    //                         <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                    //                             <div
                                                    //                                 className="progress-bar"
                                                    //                                 role="progressbar"
                                                    //                                 style={{
                                                    //                                     width: val.data.type === "quiz"
                                                    //                                         ? "100%"
                                                    //                                         : `${((val?.score?.option2 || 0) / (val?.totaloption?.total || 1)) * 100}%`,
                                                    //                                 }}

                                                    //                             >
                                                    //                                 <p className="pe-3 position-absolute progress-percent">
                                                    //                                     {parseInt(
                                                    //                                         (100 *
                                                    //                                             val?.score
                                                    //                                                 ?.option2) /
                                                    //                                         val?.totaloption
                                                    //                                             ?.total
                                                    //                                     )}
                                                    //                                     %
                                                    //                                 </p>
                                                    //                             </div>
                                                    //                         </div>
                                                    //                         <div className="progress groupchat-progress mx-0 mb-3 position-relative">
                                                    //                             <div
                                                    //                                 className="progress-bar"
                                                    //                                 role="progressbar"
                                                    //                                 style={{
                                                    //                                     width: val.data.type === "quiz"
                                                    //                                         ? "100%"
                                                    //                                         : `${((val?.score?.option3 || 0) / (val?.totaloption?.total || 1)) * 100}%`,
                                                    //                                 }}

                                                    //                             >
                                                    //                                 <p className="ps-3">
                                                    //                                     Answer
                                                    //                                 </p>
                                                    //                                 <p className="pe-3 position-absolute progress-percent">
                                                    //                                     {parseInt(
                                                    //                                         (100 *
                                                    //                                             val?.score
                                                    //                                                 ?.option3) /
                                                    //                                         val?.totaloption
                                                    //                                             ?.total
                                                    //                                     )}
                                                    //                                     %
                                                    //                                 </p>
                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </>
                                                    //                 ) : (
                                                    //                     <>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option1"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option1}
                                                    //                         </div>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option2"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option2}
                                                    //                         </div>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option3"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option3}
                                                    //                         </div>
                                                    //                     </>
                                                    //                 )}
                                                    //             </li>
                                                    //         )}

                                                    //     {val.data.type?.toLowerCase() ===
                                                    //         "quiz" && (
                                                    //             <li className="max-w-sm flex flex-col bg-[#FFFFFF] border border-[#EBE8E8] shadow-sm py-3 px-4 rounded-2xl mb-4 min-w-[300px]" >
                                                    //                 <h2 className="flex w-full justify-center item-center">
                                                    //                     <span className="text-3xl text-[#6F6CFF] font-bold">Quiz</span>
                                                    //                 </h2>

                                                    //                 <h4 className="text-lg text-black font-semibold">{val.data?.question}</h4>
                                                    //                 {val?.isresSubmited ? (
                                                    //                     <>
                                                    //                         <div className={`h-8 rounded-2xl mb-2 border ${val?.data?.option1 === val.data.answer
                                                    //                             ? 'bg-[#7EBA28] border-[#ABDC8C] text-[#ffffff]'
                                                    //                             : val?.data?.option1 !== val.data.answer && val.score.option1 === 1
                                                    //                                 ? 'bg-[#fd482c] border-[#D8D8D8] text-[#ffffff]'
                                                    //                                 : 'border-[#D8D8D8] text-black'
                                                    //                             }`} style={{
                                                    //                                 width: '100%'
                                                    //                             }}>
                                                    //                             <p className="flex justify-start item-center pl-4"><span className="text-md ">{val?.data?.option1}</span></p>
                                                    //                         </div>
                                                    //                         <div className={`h-8 rounded-2xl mb-2 border border-[#D8D8D8] ${val?.data?.option2 === val.data.answer
                                                    //                             ? 'bg-[#7EBA28] border-[#ABDC8C] text-[#ffffff]'
                                                    //                             : val?.data?.option2 !== val.data.answer && val.score.option2 === 1
                                                    //                                 ? 'bg-[#fd482c] border-[#D8D8D8] text-[#ffffff]'
                                                    //                                 : 'border-[#D8D8D8]'
                                                    //                             }`} style={{
                                                    //                                 width: '100%'
                                                    //                             }}>
                                                    //                             <p className="flex justify-start item-center pl-4"><span className="text-md ">{val?.data?.option2}</span></p>
                                                    //                         </div>
                                                    //                         <div className={`h-8 rounded-2xl mb-2 border border-[#D8D8D8] ${val?.data?.option3 === val.data.answer
                                                    //                             ? 'bg-[#7EBA28] border-[#ABDC8C] text-[#ffffff]'
                                                    //                             : val?.data?.option3 !== val.data.answer && val.score.option3 === 1
                                                    //                                 ? 'bg-[#fd482c] border-[#D8D8D8] text-[#ffffff]'
                                                    //                                 : 'border-[#D8D8D8]'
                                                    //                             }`} style={{
                                                    //                                 width: '100%'
                                                    //                             }}>
                                                    //                             <p className="flex justify-start item-center pl-4"><span className="text-md ">{val?.data?.option3}</span></p>
                                                    //                         </div>
                                                    //                     </>
                                                    //                 ) : (
                                                    //                     <>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink mb-3 clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option1"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option1}
                                                    //                         </div>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option2"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option2}
                                                    //                         </div>
                                                    //                         <div
                                                    //                             className="chat-header shadow-pink clearfix cursor_pointer"
                                                    //                             onClick={() =>
                                                    //                                 handleSubmitAns(
                                                    //                                     val.data,
                                                    //                                     "option3"
                                                    //                                 )
                                                    //                             }
                                                    //                         >
                                                    //                             {val.data?.option3}
                                                    //                         </div>
                                                    //                     </>
                                                    //                 )}
                                                    //             </li>
                                                    //         )}


                                                    //     {val?.data?.type?.toLowerCase() ===
                                                    //         "event" ? (
                                                    //         <li>
                                                    //             <div className="max-w-sm flex flex-col bg-[#FFFFFF] border border-[#EBE8E8] shadow-sm py-3 px-4 rounded-2xl mb-4">
                                                    //                 {isBetween ? (
                                                    //                     <div className="button mb-3">
                                                    //                         <button
                                                    //                             type="submit"
                                                    //                             className="button_common m-0 p-2"
                                                    //                         >
                                                    //                             <div className="button">
                                                    //                                 <button
                                                    //                                     type="submit"
                                                    //                                     onClick={() =>
                                                    //                                         renderActionButton(
                                                    //                                             val?.data
                                                    //                                         )
                                                    //                                     }
                                                    //                                     className="button_common m-0 p-2"
                                                    //                                 >
                                                    //                                     Join Group Session
                                                    //                                 </button>
                                                    //                             </div>
                                                    //                         </button>
                                                    //                     </div>
                                                    //                 ) : null}
                                                    //                 <h4>
                                                    //                     {val?.data?.question}
                                                    //                 </h4>
                                                    //                 <div className="mb-2">
                                                    //                     {val?.data?.description}{" "}
                                                    //                 </div>
                                                    //                 <div className="message-data-time p-0">
                                                    //                     Date :{" "}
                                                    //                     {moment(
                                                    //                         val?.data?.startDate
                                                    //                     ).format("LL")}
                                                    //                 </div>
                                                    //                 <div className="message-data-time p-0">
                                                    //                     Start Time :{" "}
                                                    //                     {val?.data?.startTime}
                                                    //                 </div>
                                                    //                 <div className="message-data-time p-0">
                                                    //                     End Time :{" "}
                                                    //                     {val?.data?.endTime}
                                                    //                 </div>
                                                    //             </div>
                                                    //         </li>
                                                    //     ) : null}


                                                    // </React.Fragment>
                                                );
                                            })
                                        )}
                                    </ul>
                                </div>
                            )}

                            {activeTab === 2 && (
                                <ResourceComponent allResourceData={allResourceData} />
                            )}
                        </div>


                    </div>
                </div>
                <div className="flex-none hero_area relative z-0">
                        <svg
                            className="waves"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 24 150 28"
                            preserveAspectRatio="none"
                            shapeRendering="auto"
                        >
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
                            </g>
                        </svg>
                    </div>
            </div>
        </>

    )
}

export default MobileGroupDetailsPage