import React from 'react'

const TherapistProfile = ({isOpen, data, onClose }) => {
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-lg md:max-w-[90%] relative overflow-hidden flex flex-col md:py-4 px-4 bg-white">
                <button
                    className="absolute top-2 right-2 text-2xl font-bold text-gray-700 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                <div className='flex flex-col'>

                </div>


            </div>
        </div>
    )
}

export default TherapistProfile