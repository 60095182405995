import React, { useRef } from 'react';
import Slider from 'react-slick';
import PlaygroundCard from "./PlaygroundCard";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";


const NextArrow = ({ onClick, bgcolor }) => {
    return (
        <div
            onClick={onClick}
            className="text-black cursor-pointer p-2 rounded-full ml-2 shadow-md" style={{background : bgcolor}}
        >
            <GrFormNext size={20} />
        </div>
    );
};


const PrevArrow = ({ onClick, bgcolor }) => {
    return (
        <div
            onClick={onClick}
            className="text-black cursor-pointer p-2 rounded-full mr-2 shadow-md" style={{background : bgcolor}}
        >
            <GrFormPrevious size={20} />
        </div>
    );
};

const SliderComponent = ({ name, data, buttonBg, cardBg, onCardClick  }) => {
    const sliderRef = useRef(null);  

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default for larger screens
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Target screens smaller than 768px
                settings: {
                    slidesToShow: 1, // Show 1 slide
                    slidesToScroll: 1,
                },
            },
        ],
    };
    

    return (
        <div className="w-full mx-auto bg-transparent">
            <div className="flex items-center mb-4">
                <span
                    style={{ backgroundColor: buttonBg }}
                    className="py-2 px-4 shadow-md text-sm md:text-xl font-bold rounded-full text-white"
                >
                    {name}
                </span>

                {/* Render Custom Arrows next to the span */}
                <div className="flex ml-auto">
                    <PrevArrow onClick={() => sliderRef.current.slickPrev()} bgcolor = {cardBg} />
                    <NextArrow onClick={() => sliderRef.current.slickNext()} bgcolor = {cardBg}/>
                </div>
            </div>

            {/* Attach ref to the Slider */}
            <Slider ref={sliderRef} {...settings}>
                {data.length > 0 && data.map((item, index) => (
                    <PlaygroundCard key={index} data={item} cardBg={cardBg} onClick={onCardClick}/>
                ))}
            </Slider>
        </div>
    );
};

export default SliderComponent;
