import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import SideImage from '../../Assets/Images/workshop/workshopPageImg.png';
import API from '../../Api/Api';
import WorkshopCard from './WorkshopCard';
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import AdminRoute from "../../Route/RouteDetails";


const Workshop = () => {
  const [workshopDetails, setWorkshopDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const UserData = JSON.parse(localStorage.getItem("ClientUserData"));
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      API.WorkshopApis.getAllWorkshop().then((response) => {
        if (response.status === 200) {
          console.log('Response', response);
          setWorkshopDetails(response?.data?.data_all);
          setLoading(false)
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [])



  return (
    // <div className="flex flex-col bg-white mx-0 w-full h-full min-h-screen" >
    //   <div className="flex-grow bg-white m-0 w-full p-0" >
    //     <Header />
    //     <Sidebar />
    //     <div className="flex flex-grow md:ml-[8%] h-full md:mr-[3%] md:mt-8 relative z-1 p-4 rounded-3xl shadow-md min-h-[80vh] mt-4 bg-[#F5F6FB80] border border-[#E7F0FC]" >
    //       <div className="flex-grow h-full bg-transparent rounded-3xl">
    //         <span className='text-black font-semibold text-3xl'>Upcoming Events</span>
    //         <div className='grid grid-cols-3 gap-10 mt-4 max-h-[65vh] overflow-y-auto overflow-x-hidden scrollbar-hide'>
    //           {workshopDetails.length > 0 && workshopDetails.map((workshop, index) => (
    //             <WorkshopCard key={index} data={workshop} onClick={() => navigate(`${AdminRoute.Common.WorkshopDetails}/${workshop._id}`)} /> //onClick={() => handlePayment(workshop)}
    //           ))}
    //         </div>
    //       </div>
    //       <div className="w-[180px] h-full bg-transparent flex items-end justify-end ">
    //         <img src={SideImage} className="h-full " />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="hero_area relative z-0">
    //     <svg
    //       className="waves"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 24 150 28"
    //       preserveAspectRatio="none"
    //       shapeRendering="auto"
    //     >
    //       <defs>
    //         <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    //       </defs>
    //       <g className="parallax">
    //         <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
    //         <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
    //         <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
    //         <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
    //       </g>
    //     </svg>
    //   </div>


    // </div>
    <div className="grid grid-rows-[auto_1fr_auto] bg-white w-full h-screen overflow-hidden">
      <Sidebar />
      <div className="flex-none bg-white">
        <Header />
      </div>
      <div
        className="row-span-1 lg:ml-[8%] md:ml-[14%] md:mr-[3%] md:my-8 overflow-hidden relative z-1 rounded-2xl p-2 bg-[#F5F6FB80] border border-[#E7F0FC]"
      >
        <div className="flex">
          <div className="flex-grow h-full p-2">
            <span className="text-black font-semibold text-3xl">Upcoming Events</span>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-4 overflow-y-auto overflow-x-hidden scrollbar-hide max-h-[calc(100vh-250px)]">
              {workshopDetails.length > 0 &&
                workshopDetails.map((workshop, index) => (
                  <WorkshopCard
                    key={index}
                    data={workshop}
                    onClick={() => {
                      navigate(`${AdminRoute.Common.WorkshopDetails}/${workshop._id}`);
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="hidden w-[180px] h-full bg-transparent lg:flex items-end justify-end">
            <img src={SideImage} className="h-full" />
          </div>
        </div>

      </div>
      <div className="flex-none hero_area relative z-0">
        <svg
          className="waves w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Workshop;