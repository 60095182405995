import React from 'react';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";

const WorkshopCard = ({ data, onClick }) => {
    const date = new Date(data.eventDate);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
    }).format(date);
    return (
        <div className="max-w-sm rounded-3xl overflow-hidden shadow-md bg-white">
            <img className="w-full" src={data.eventImage} alt="Event Image" />
            <div className='flex flex-col p-3'>
                <div className="">
                    <div className="font-bold text-sm lg:text-xl mb-2">{data.eventName}</div>
                    <p className="text-gray-700 text-sm lg:text-base">
                        {data.eventDescription.length > 60 ? data.eventDescription.slice(0, 60) + '...' : data.eventDescription}
                    </p>
                </div>
                <div className='w-full flex justify-between'>
                    <div className="flex justify-end pt-4 flex-col">
                        <div className="text-xs lg:text-sm mb-2 flex text-gray-700 "><FaRegCalendarAlt className='mr-2' size={18} />{formattedDate}</div>
                        <div className="text-xs lg:text-sm mb-2 flex text-gray-700"><FaRegClock className='mr-2' size={18} />{data.eventStartTime}{" - "}{data.eventEndTime} </div>
                    </div>
                    <div className='flex flex-grow flex-col justify-center items-center h-full'>
                        <div className="text-2xl font-bold flex items-center text-[#28BA82] mb-1">
                            <FaRupeeSign className="mr-1" size={25} />
                            {data.amount}
                        </div>
                        <button className='bg-[#FFB81D] px-4 py-2 text-black text-xs md:text-sm rounded-lg shadow-lg' onClick={onClick}>
                            View Details
                        </button>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default WorkshopCard