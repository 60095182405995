/* eslint-disable */

import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import React, { useEffect, useState, useContext } from "react";
import API from "../../Api/Api";
import moment from "moment";
import commoan from "../../environment/Config";
import blankPageBG from "../../Assets/Images/DigitalNotes/NewNotes/newnotesbg.png";
import blackPageImage from "../../Assets/Images/DigitalNotes/NewNotes/New.png";
import { FaPlus } from "react-icons/fa";
import typefieldbg from "../../Assets/Images/DigitalNotes/TypeFeild/TypeFieldBG.png";
import CreateNotesComponents from "./CreateNotes";
import { toast } from "react-toastify";
import noteslistbg from "../../Assets/Images/DigitalNotes/showListBg.png";
import DigitalNotesListComponent from "./DigitalNotesList";
import DigitalNotesDetails from "./DigitalNotesDetails";
import showDetailsBg from "../../Assets/Images/DigitalNotes/SeeDetailedNotesBg.png";
import { CiLock } from "react-icons/ci";
import { PlanContext } from "../../ContextApi/PlanContext";

function DigitalNotes() {
  const [DigitalNotesList, setDigitalNotesList] = useState([]);
  const [DigitalNotesdetails, setDigitalNotesdetails] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [openDiary, setOpenDiary] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedNote, setSelectedNote] = useState([]);
  const [userImage, setUserImage] = useState(null);
  const { planDetails } = useContext(PlanContext);


  //api call for digital notes list

  const handleNoteClick = (note) => {
    setSelectedNote(note);  // Store the clicked note's data
    setViewDetails(true);    // Show the details view
  };


  const getDigitalNoteList = () => {
    setLoadingList(true);
    try {
      API.Auth.DigitalNotesList().then((response) => {
        if (response.status === 200) {
          console.log('DigitalNotesList', response);
          setDigitalNotesList(response?.data?.data_all);
          if (response.data.data_all[0]?._id) {
            getNotesdetailsbyits_id(response.data.data_all[0]?._id);
          }
          setLoadingList(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  useEffect(() => {
    getDigitalNoteList();
    try {
      API.Auth.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          setUserImage(response?.data?.data.image);
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log('planDetails', planDetails)
  }, []);

  // api call for details digital notes by its id

  const getNotesdetailsbyits_id = (id) => {
    setLoadingNotes(true);
    try {
      API.Auth.DigitalNotesDetails({
        notes_id: id,
      }).then((response) => {
        if (response.status === 200) {
          setDigitalNotesdetails(response?.data?.data_all[0]);
          // updateNoteRead(id)
          setLoadingNotes(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingNotes(false);
    }
  };

  const getNotesdetails = (val) => {
    getNotesdetailsbyits_id(val?._id);
    NotesReadMag(val?._id);
  };

  const NotesReadMag = (id) => {
    API.Notes.UpdateNotes({
      update_id: id,
      data: {
        is_seen: 1,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // setDigitalNotesList(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCreateNotesClose = () => {
    setOpenDiary(false);
    getDigitalNoteList();
  }

  //jsx
  return (
    <>
      {/* <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading  mt-1 mb-1">Digital Notes</h2>
                  <Button
                    type="submit"
                    className="button_common notes-btn create-new_notes"
                  >
                    <NavLink to={AdminRoute?.Common?.CreateNotes}>
                      Create New Notes
                    </NavLink>
                  </Button>
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-12">
              <Button
                type="submit"
                className="button_common notes-btn create-new_notes d-block d-md-none mt-2 me-0"
              >
                <NavLink to={AdminRoute?.Common?.CreateNotes}>
                  Create New Notes
                </NavLink>
              </Button>
            </div>
            <div className="col-lg-3 col-md-12  mb-2 list-item">
              <div className="dashboard-inner v-height-digital-notes-page">
                <div className="v-height-digital-notes-page-notes">
                  {loadingList ? (
                    <div className="container_loader"></div>
                  ) : DigitalNotesList.length === 0 ? (
                    <div className="alert alert-warning mt-2" role="alert">
                      Data Not Found
                    </div>
                  ) : (
                    DigitalNotesList &&
                    DigitalNotesList.map((val) => {
                      return (
                        <div
                          className="dashboard-text-div notes-left-div mb-4 cursor_pointer"
                          onClick={(e) => getNotesdetails(val)}
                          key={val._id}
                        >
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-10">
                              <h3 className="secheading">{val?.title}</h3>
                            </div>
                            <div className="col-lg-2">
                              {!val?.is_seen && val.response ? (
                                <i
                                  className="fa fa-star text-warning"
                                  aria-hidden="true"
                                ></i>
                              ) : null}
                            </div>
                          </div>
                          <p className="date-text">
                            {moment(val?.createdAt).format("LL")}
                          </p>
                          <p className="mt-0 notes-text">
                            {val?.description.length > 40
                              ? `${val?.description.substring(0, 40)}....`
                              : val?.description}
                          </p>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 mb-2">
              <div
                style={{
                  backgroundImage: `url(${commoan.baseUrl}images/${
                    DigitalNotesdetails?.theme &&
                    DigitalNotesdetails?.theme[0]?.backgroundimagename
                  })`,
                  backgroundRepeat: "no-repeat",
                }}
                className="dashboard-inner notes-right-div px-0 px-md-3"
              >
                {loadingNotes ? (
                  <span>Loading...</span>
                ) : DigitalNotesList.length === 0 ? (
                  <div className="alert alert-warning" role="alert">
                    Data Not Found
                  </div>
                ) : (
                  <div
                    className="dashboard-text-div px-3 px-md-0"
                    value={DigitalNotesList?.length - 1}
                  >
                    <h3 className="secheading mt-3">
                      {DigitalNotesdetails?.title}
                    </h3>
                    <p>{DigitalNotesdetails?.description}</p>
                  </div>
                )}
              </div>
              {DigitalNotesdetails?.response &&
              DigitalNotesdetails?.response?.responce ? (
                <div className="dashboard-text-div  notes-right-last-div mt-4">
                  <h6>Response</h6>
                  <h3 className="secheading mt-3">
                    {DigitalNotesdetails?.response?.theropistname}
                  </h3>
                  <p>{DigitalNotesdetails?.response?.responce}</p>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div> */}
      {/* <div className="grid grid-rows-[auto_1fr_auto] bg-white mx-0 w-full h-screen">
        <Sidebar />
        <div className="flex-none bg-white">
          <Header />
        </div>
        <div className="flex-grow flex flex-col bg-white md:flex-col lg:flex-row md:ml-[8%] h-full md:mr-[4%] md:my-8 ">
          {DigitalNotesList.length === 0 && !openDiary && (
            <div
              className="flex flex-col w-full justify-center items-center rounded-[30px] z-1"
              style={{
                background: `url(${blankPageBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="w-auto h-80 flex justify-center items-center">
                <img
                  src={blackPageImage}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-black mt-2 font-bold text-xl">Start Your Journey</span>
              <span className="text-black mt-2 text-sm text-center px-4">Embrace your journey every step matters in mental health</span>
              {planDetails != null && planDetails.includedPages.includes("DigitalNotes") ? (
                <button className="bg-[#F5D741] p-2.5 text-white rounded-full mt-4" onClick={() => setOpenDiary(true)}>
                  <FaPlus size={30} />
                </button>) : (
                <button className="bg-[#F5D741] p-2.5 text-white rounded-full mt-4">
                  <CiLock size={30} />
                </button>
              )}
            </div>
          )}
          {DigitalNotesList.length > 0 && !openDiary && !viewDetails && (
            <div
              className="flex flex-col h-full w-full p-8 justify-start items-start rounded-3xl z-1"
              style={{
                background: `url(${noteslistbg})`,
                backgroundSize: 'auto',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="flex flex-col w-full md:w-3/5">
                <div className="flex mb-8 justify-between items-center px-4">
                  <span className="text-sm md:text-3xl text-[#000000D4] font-bold">MY DIGITAL NOTES</span>
                  {planDetails != null && planDetails.includedPages.includes("DigitalNotes") ? (
                    <button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg" onClick={() => setOpenDiary(true)}>
                      + Start Writing
                    </button>) : (<button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg">
                      <span className="flex justify-center items-center"><CiLock size={18} className="mr-1" /> Start Writing</span>
                    </button>)}
                </div>
                <div className="flex-grow w-full p-4 overflow-y-auto max-h-[80vh] min-h-[80vh] scrollbar-none radialGradient ">
                  {DigitalNotesList.length > 0 &&
                    DigitalNotesList.map((note, index) => (
                      <DigitalNotesListComponent key={index} data={note} image={userImage} onClick={() => handleNoteClick(note)} />
                    ))}
                </div>
              </div>
            </div>
          )}
          {openDiary && (
            <div
              className="flex flex-col min-h-screen w-full rounded-[30px] z-1 p-4 justify-start"
              style={{
                background: `url(${typefieldbg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CreateNotesComponents onClose={handleCreateNotesClose} />
            </div>
          )}
          {viewDetails && !openDiary && (
            <div
              className="flex flex-col min-h-screen w-full rounded-[30px] z-1 p-2 md:p-4 justify-start"
              style={{
                background: `url(${showDetailsBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="flex flex-col w-full md:w-3/5">
                <div className="flex mb-8 justify-between items-center px-4 md:px-0">
                  <span className="text-sm md:text-3xl text-[#000000D4] font-bold">MY DIGITAL NOTES</span>
                  <button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg" onClick={() => setViewDetails(false)}>
                    Back
                  </button>
                </div>
                <div className="flex-grow w-full p-4 overflow-y-auto max-h-[80vh] min-h-[80vh] scrollbar-none radialGradient ">
                  <DigitalNotesDetails data={selectedNote} image={userImage} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex-none hero_area relative z-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
          </g>
        </svg>
      </div> */}
      <div className="grid grid-rows-[auto_1fr_auto] bg-white mx-0 w-full h-screen">
        {/* Sidebar */}
        <Sidebar />

        {/* Header */}
        <div className="flex-none bg-white">
          <Header />
        </div>

        {/* Main Content */}
        <div className="row-span-1 flex flex-col bg-white lg:ml-[8%] md:ml-[14%] md:mr-[4%] md:my-8 overflow-hidden">
          {DigitalNotesList.length === 0 && !openDiary && (
            <div
              className="flex flex-col w-full justify-center items-center rounded-[30px] z-1 h-full"
              style={{
                background: `url(${blankPageBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="w-auto h-80 flex justify-center items-center">
                <img
                  src={blackPageImage}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-black mt-2 font-bold text-xl">Start Your Journey</span>
              <span className="text-black mt-2 text-sm text-center px-4">Embrace your journey every step matters in mental health</span>
              {planDetails != null && planDetails.includedPages.includes("DigitalNotes") ? (
                <button className="bg-[#F5D741] p-2.5 text-white rounded-full mt-4" onClick={() => setOpenDiary(true)}>
                  <FaPlus size={30} />
                </button>
              ) : (
                <button className="bg-[#F5D741] p-2.5 text-white rounded-full mt-4">
                  <CiLock size={30} />
                </button>
              )}
            </div>
          )}

          {DigitalNotesList.length > 0 && !openDiary && !viewDetails && (
            <div
              className="flex flex-col flex-grow w-full p-8 justify-start items-start rounded-3xl z-1 overflow-hidden"
              style={{
                background: `url(${noteslistbg})`,
                backgroundSize: 'auto',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="flex flex-col w-full lg:w-3/5">
                <div className="flex mb-8 justify-between items-center md:px-4">
                  <span className="text-sm md:text-3xl text-[#000000D4] font-bold">MY DIGITAL NOTES</span>
                  {planDetails != null && planDetails.includedPages.includes("DigitalNotes") ? (
                    <button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg" onClick={() => setOpenDiary(true)}>
                      + Start Writing
                    </button>
                  ) : (
                    <button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg">
                      <span className="flex justify-center items-center"><CiLock size={18} className="mr-1" /> Start Writing</span>
                    </button>
                  )}
                </div>
                <div className="flex-grow w-full p-4 overflow-y-auto radialGradient max-h-[calc(100vh-300px)] scrollbar-hide">
                  {DigitalNotesList.length > 0 &&
                    DigitalNotesList.map((note, index) => (
                      <DigitalNotesListComponent key={index} data={note} image={userImage} onClick={() => handleNoteClick(note)} />
                    ))}
                </div>
              </div>
            </div>
          )}

          {openDiary && (
            <div
              className="flex flex-col h-full w-full rounded-[30px] z-1 p-4 justify-start overflow-hidden"
              style={{
                background: `url(${typefieldbg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CreateNotesComponents onClose={handleCreateNotesClose} />
            </div>
          )}

          {viewDetails && !openDiary && (
            <div
              className="flex flex-col h-full w-full rounded-[30px] z-1 p-4 justify-start overflow-hidden"
              style={{
                background: `url(${showDetailsBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="flex flex-col w-full lg:w-3/5">
                <div className="flex mb-8 justify-between items-center px-4 md:px-0">
                  <span className="text-sm md:text-3xl text-[#000000D4] font-bold">MY DIGITAL NOTES</span>
                  <button className="bg-[#F8D68B] py-2 px-4 text-black rounded-full font-semibold outline-none text-xs md:text-lg" onClick={() => setViewDetails(false)}>
                    Back
                  </button>
                </div>
                <div className="flex-grow w-full p-4 overflow-y-auto radialGradient max-h-[calc(100vh-300px)] scrollbar-hide">
                  <DigitalNotesDetails data={selectedNote} image={userImage} />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Footer (Waves) */}
        <div className="flex-none hero_area relative z-0">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255, 192, 203, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(169, 169, 169, 0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(169, 169, 169, 0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(255, 192, 203, 1)" />
            </g>
          </svg>
        </div>
      </div>

    </>
  );
}

export default DigitalNotes;
